import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import RecetteContext from "../context/RecetteContext"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import NumberFormat from 'react-number-format';

const UpdateTresor = ({onShow, change}) => {

    const recetteContext = useContext(RecetteContext)
    const { selectedFinance, updateFinance, clearFinance, clearSeletedFinance } = recetteContext
    const [startDate, setStartDate] = useState();
    const [newTresor, setNewTresor] = useState({
        id:"",
        dateComptable: startDate, igu: "", nbre_operation_ogapi: "0",
        dossier: "",denomination: "", sigle: "", dirigeant: "", forme_juridique: "",
        nationalite: "", nature: "", procedure_express: "", frais: "", situation_dossier: "", observation: "",
        quantite: "",
        ordre: "", total: "0",
        greffe: "", ogapi: "",
        insertionJournal: "",
        totalFinal: ""
    })

    const [totalFrais, setTotalFrais] = useState(0);
    const [errors, setErrors] = useState(false)
    const [dgiTemp, setDgiTemp] = useState(0)
    const [anpiTemp, setAnpiTemp] = useState(0)
    const [greffeTemp, setGreffeTemp] = useState(0)
    const [ogapiTemp, setOgapiTemp] = useState(0)
    const [journalTemp, setJournalTemp] = useState(0)
    const [colorQt, setcolorQt] = useState("none")
    const [colorOrdre, setColorOrdre] = useState("none")

    let {  
        id,
        dateComptable, igu, nbre_operation_ogapi,
        dossier, denomination, sigle, dirigeant, forme_juridique,
        nationalite, nature, procedure_express, frais,  anpi, dgi, greffe, situation_dossier, observation,
        ordre,
        ogapi,
        insertionJournal
    } = newTresor

    useEffect(() => {
        if(selectedFinance !== null) {
           
            setNewTresor(selectedFinance)
            console.log(selectedFinance)
            
            setStartDate(moment(selectedFinance.dateComptable).toDate()) 
            
            
            // Utilisation de variable temporaire pour eviter NaN
            setTotalFrais(parseInt(selectedFinance.frais))
            setDgiTemp(parseInt(selectedFinance.dgi))
            setAnpiTemp(parseInt(selectedFinance.anpi))
            setGreffeTemp(parseInt(selectedFinance.greffe))
            setOgapiTemp(parseInt(selectedFinance.ogapi))
            setJournalTemp(parseInt(selectedFinance.insertionJournal)) 
        }

    }, [selectedFinance])

    useEffect(() => {
        handleTotalFrais()
    }, [greffeTemp, anpiTemp, dgiTemp, 
        ogapiTemp, journalTemp])


    const handleOrdre = (e) => {

    }    

    const handleModal = () => {
        change()
        clearSeletedFinance()
    }

    const handleValue = (e) => {
          setNewTresor({ ...newTresor, [e.target.name]: e.target.value})
      }

      const onChangeDate = (date) => {
        setStartDate(date)
   }
    const handleUpdate = (e) => {
        e.preventDefault();

        newTresor.dateComptable = startDate;

        newTresor.frais = totalFrais;
        newTresor.anpi = anpiTemp;
        newTresor.dgi = dgiTemp;
        newTresor.greffe = greffeTemp;
        newTresor.ogapi = ogapiTemp;
        if(newTresor.ogapi > 0) {
            newTresor.nbre_operation_ogapi = 1;
        } else {
            newTresor.nbre_operation_ogapi = 0;
        }
        newTresor.insertionJournal = journalTemp;

        updateFinance(newTresor)

        setNewTresor({
            id:"",
            dateComptable: startDate, igu: "", nbre_operation_ogapi: "0",
            dossier: "",denomination: "", sigle: "", dirigeant: "", forme_juridique: "EI",
            nationalite: "", nature: "CR", procedure_express: "non", frais: "", situation_dossier: "", observation: "",
            quantite: "",
            ordre: "", total: "0",
            greffe: "", ogapi: "",
            insertionJournal: "",
            totalFinal: ""
        })
       // clearFinance()
        handleModal()
    }  

    const handleTotalFrais = () => {
        if(isNaN(anpiTemp)) {
            setAnpiTemp(0)
        }
        if(isNaN(dgiTemp)) {
            setDgiTemp(0)
        }
        if(isNaN(greffeTemp)) {
            setGreffeTemp(0)
        }
        if(isNaN(ogapiTemp)) {
            setOgapiTemp(0)
        }
        if(isNaN(journalTemp)) {
            setJournalTemp(0)
        }
       
       setTotalFrais(anpiTemp + dgiTemp + greffeTemp + ogapiTemp + journalTemp)
   }
 
    
    // total ligne
   

    const handleDgiTemp = (e) => {
        
        setDgiTemp(parseInt(e))
    }

    const handleAnpiTemp = (e) => {
        
        setAnpiTemp(parseInt(e))
    }


    const handleGreffeTemp = (e) => {
        
        setGreffeTemp(parseInt(e))
    }

    const handleOgapiTemp = (e) => {
        
        setOgapiTemp(parseInt(e))
    }

    const handleJournalTemp = (e) => {
        
        setJournalTemp(parseInt(e))
    }

    return (
        <Fragment>
          <Modal size="lg" show={onShow} onHide={handleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Mise à jour </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "gray"}}>
                <form onSubmit={handleUpdate}>

                  <div className="form-row" >
                        <div className="form-group col-md-4">
                            <label htmlFor="dateComptable">Date Comptable</label>
                            <DatePicker className="form-control" 
                                        selected={startDate}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={onChangeDate}  />
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="">IGU</label>  
                           
                            <select className="form-control" name="igu" value={igu} onChange={handleValue}>
                                <option value=""></option>
                                <option value="Flore">Flore</option>
                                <option value="Lyvia">Lyvia</option>
                                <option value="Oyila Simplice">Oyila Simplice</option>
                                <option value="Eude">Eude</option>
                                <option value="oyem">Oyem</option>
                                <option value="fcv">FCV</option>
                                <option value="mla">MLA</option>
                                <option value="lbrn">LBRN</option>
                                <option value="valerie">Valerie</option>
                                <option value="ogapi">Ogapi</option>
                            </select>
                        </div>


                        <div className="form-group col-md-4">
                            <label htmlFor="">NºDossier</label>  
                            <input type="text" className="form-control"
                                                required
                                                style={{ backgroundColor: colorQt}}
                                                name="dossier" 
                                                onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        //inputOrdreRef.current.focus()
                                                    }
                                                }} //ref={qtRef}
                                                value={dossier}
                                                onChange={handleValue} placeholder="NºDossier"/>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="denomination">Dénomination Sociale</label>  
                            <input type="text" className="form-control"
                                                 style={{ backgroundColor: colorOrdre}}
                                                 name="denomination" 
                                                 value={denomination}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        //formExpressRef.current.focus()
                                                    }
                                                }} //ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="Dénomination Sociale"/>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="sigle">Sigle</label>  
                            <input type="text" className="form-control"
                                                 style={{ backgroundColor: colorOrdre}}
                                                 name="sigle" 
                                                 value={sigle}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        //formExpressRef.current.focus()
                                                    }
                                                }} //ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="Sigle"/>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="dirigeant">Nom et prénom du dirigeant</label>  
                            <input type="text" className="form-control"
                                                 style={{ backgroundColor: colorOrdre}}
                                                 name="dirigeant" 
                                                 value={dirigeant}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        //formExpressRef.current.focus()
                                                    }
                                                }} //ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="Nom et prenom du dirigeant"/>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="forme_juridique">Forme Juridique</label> 
                            <select className="form-control" name="forme_juridique"  value={forme_juridique} onChange={handleValue}>
                                <option value="EI">Entreprise individuelle (EI)</option>
                                <option value="PM">Personne Morale (PM)</option>
                            </select> 
                            {/* <input type="text" className="form-control"
                                                 style={{ backgroundColor: colorOrdre}}
                                                 name="forme_juridique" 
                                                 value={forme_juridique}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        //formExpressRef.current.focus()
                                                    }
                                                }} //ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="Forme Juridique"/> */}
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="nationalite">Nationalité</label>  
                            {/* <input type="text" className="form-control"
                                                 style={{ backgroundColor: colorOrdre}}
                                                 name="nationalite" 
                                                 value={nationalite}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        //formExpressRef.current.focus()
                                                    }
                                                }} //ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="nationalite"/> */}
                            <select  className="form-control" 
                                    name="nationalite"  value={nationalite} onChange={handleValue}>
                                <option value=""></option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Afrique_du_Sud">Afrique du Sud</option>
                                <option value="Albanie">Albanie</option>
                                <option value="Algerie">Algérie</option>
                                <option value="Allemagne">Allemagne</option>
                                <option value="Andorre">Andorre</option>
                                <option value="Angola">Angola</option>
                                <option value="Antigua-et-Barbuda">Antigua-et-Barbuda</option>
                                <option value="Arabie_saoudite">Arabie saoudite</option>
                                <option value="Argentine">Argentine</option>
                                <option value="Armenie">Arménie</option>
                                <option value="Australie">Australie</option>
                                <option value="Autriche">Autriche</option>
                                <option value="Azerbaidjan">Azerbaïdjan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrein">Bahreïn</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbade">Barbade</option>
                                <option value="Belau">Belau</option>
                                <option value="Belgique">Belgique</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Bénin</option>
                                <option value="Bhoutan">Bhoutan</option>
                                <option value="Bielorussie">Biélorussie</option>
                                <option value="Birmanie">Birmanie</option>
                                <option value="Bolivie">Bolivie</option>
                                <option value="Bosnie-Herzégovine">Bosnie-Herzégovine</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bresil">Brésil</option>
                                <option value="Brunei">Brunei</option>
                                <option value="Bulgarie">Bulgarie</option>
                                <option value="Burkina">Burkina</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodge">Cambodge</option>
                                <option value="Cameroun">Cameroun</option>
                                <option value="Canada">Canada</option>
                                <option value="Cap-Vert">Cap-Vert</option>
                                <option value="Chili">Chili</option>
                                <option value="Chine">Chine</option>
                                <option value="Chypre">Chypre</option>
                                <option value="Colombie">Colombie</option>
                                <option value="Comores">Comores</option>
                                <option value="Congo">Congo</option>
                                <option value="Cook">Cook</option>
                                <option value="Coree_du_Nord">Corée du Nord</option>
                                <option value="Coree_du_Sud">Corée du Sud</option>
                                <option value="Costa_Rica">Costa Rica</option>
                                <option value="Cote_Ivoire">Côte d'Ivoire</option>
                                <option value="Croatie">Croatie</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Danemark">Danemark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominique">Dominique</option>
                                <option value="Egypte">Égypte</option>
                                <option value="Emirats_arabes_unis">Émirats arabes unis</option>
                                <option value="Equateur">Équateur</option>
                                <option value="Erythree">Érythrée</option>
                                <option value="Espagne">Espagne</option>
                                <option value="Estonie">Estonie</option>
                                <option value="Etats-Unis">États-Unis</option>
                                <option value="Ethiopie">Éthiopie</option>
                                <option value="Fidji">Fidji</option>
                                <option value="Finlande">Finlande</option>
                                <option value="France">France</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambie">Gambie</option>
                                <option value="Georgie">Géorgie</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Grèce">Grèce</option>
                                <option value="Grenade">Grenade</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinee">Guinée</option>
                                <option value="Guinee-Bissao">Guinée-Bissao</option>
                                <option value="Guinee_equatoriale">Guinée équatoriale</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haïti</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hongrie">Hongrie</option>
                                <option value="Inde">Inde</option>
                                <option value="Indonesie">Indonésie</option>
                                <option value="Iran">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Irlande">Irlande</option>
                                <option value="Islande">Islande</option>
                                <option value="Israël">Israël</option>
                                <option value="Italie">Italie</option>
                                <option value="Jamaique">Jamaïque</option>
                                <option value="Japon">Japon</option>
                                <option value="Jordanie">Jordanie</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kirghizistan">Kirghizistan</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Koweit">Koweït</option>
                                <option value="Laos">Laos</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Lettonie">Lettonie</option>
                                <option value="Liban">Liban</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libye">Libye</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lituanie">Lituanie</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macedoine">Macédoine</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malaisie">Malaisie</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malte">Malte</option>
                                <option value="Maroc">Maroc</option>
                                <option value="Marshall">Marshall</option>
                                <option value="Maurice">Maurice</option>
                                <option value="Mauritanie">Mauritanie</option>
                                <option value="Mexique">Mexique</option>
                                <option value="Micronesie">Micronésie</option>
                                <option value="Moldavie">Moldavie</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolie">Mongolie</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Namibie">Namibie</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Népal</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norvège">Norvège</option>
                                <option value="Nouvelle-Zelande">Nouvelle-Zélande</option>
                                <option value="Oman">Oman</option>
                                <option value="Ouganda">Ouganda</option>
                                <option value="Ouzbekistan">Ouzbékistan</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Panama">Panama</option>
                                <option value="Papouasie-Nouvelle_Guinee">Papouasie - Nouvelle Guinée</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Pays-Bas">Pays-Bas</option>
                                <option value="Perou">Pérou</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pologne">Pologne</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Republique_centrafricaine">République centrafricaine</option>
                                <option value="Republique_dominicaine">République dominicaine</option>
                                <option value="Republique_tcheque">République tchèque</option>
                                <option value="Roumanie">Roumanie</option>
                                <option value="Royaume-Uni">Royaume-Uni</option>
                                <option value="Russie">Russie</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint-Christophe-et-Nieves">Saint-Christophe-et-Niévès</option>
                                <option value="Sainte-Lucie">Sainte-Lucie</option>
                                <option value="Saint-Marin">Saint-Marin </option>
                                <option value="Saint-Siège">Saint-Siège, ou leVatican</option>
                                <option value="Saint-Vincent-et-les_Grenadines">Saint-Vincent-et-les Grenadines</option>
                                <option value="Salomon">Salomon</option>
                                <option value="Salvador">Salvador</option>
                                <option value="Samoa_occidentales">Samoa occidentales</option>
                                <option value="Sao_Tome-et-Principe">Sao Tomé-et-Principe</option>
                                <option value="Senegal">Sénégal</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra_Leone">Sierra Leone</option>
                                <option value="Singapour">Singapour</option>
                                <option value="Slovaquie">Slovaquie</option>
                                <option value="Slovenie">Slovénie</option>
                                <option value="Somalie">Somalie</option>
                                <option value="Soudan">Soudan</option>
                                <option value="Sri_Lanka">Sri Lanka</option>
                                <option value="Sued">Suède</option>
                                <option value="Suisse">Suisse</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Swaziland">Swaziland</option>
                                <option value="Syrie">Syrie</option>
                                <option value="Tadjikistan">Tadjikistan</option>
                                <option value="Tanzanie">Tanzanie</option>
                                <option value="Tchad">Tchad</option>
                                <option value="Thailande">Thaïlande</option>
                                <option value="Togo">Togo</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinite-et-Tobago">Trinité-et-Tobago</option>
                                <option value="Tunisie">Tunisie</option>
                                <option value="Turkmenistan">Turkménistan</option>
                                <option value="Turquie">Turquie</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Viet_Nam">Viêt Nam</option>
                                <option value="Yemen">Yémen</option>
                                <option value="Yougoslavie">Yougoslavie</option>
                                <option value="Zaire">Zaïre</option>
                                <option value="Zambie">Zambie</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="nature">Nature Operation</label>  
                            <select className="form-control" name="nature" value={nature} onChange={handleValue}>
                                <option value="CR">CR</option>
                                <option value="MOD">MOD</option>
                                <option value="CES">CES</option>
                                <option value="HAR">HAR</option>
                                <option value="DUP">DUP</option>
                                <option value="REDACTION_DES_ACTES">REDACTION DES ACTES</option>
                                <option value="AUTRE">AUTRE...</option>
                            </select>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="procedure_express">Procedure Express</label>  
                            <select className="form-control" name="procedure_express" value={procedure_express} onChange={handleValue}>
                                <option value="non">NON</option>
                                <option value="oui">OUI</option>
                            </select>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="NºOR">NºORDRE</label>  
                            <input type="number" className="form-control"
                                                 style={{ backgroundColor: colorOrdre}}
                                                 name="ordre" onKeyUp={handleOrdre} required
                                                 value={ordre}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        //formExpressRef.current.focus()
                                                    }
                                                }} //ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="NºORDRE"/>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="situation_dossier">Situation du dossier</label>  
                            <input type="text" className="form-control"
                                                 name="situation_dossier"
                                                 value={situation_dossier}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        //formExpressRef.current.focus()
                                                    }
                                                }} //ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="Situation du dossier"/>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="observation">Observation</label>  
                            <input type="text" className="form-control"
                                                 name="observation"
                                                 value={observation}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        //formExpressRef.current.focus()
                                                    }
                                                }} //ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="Observation"/>
                        </div>
                        </div>

                        
                        <br/>
                        <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="dfde">Anpi</label>  
                                    {/* <input type="number" className="form-control"
                                        name="anpi" onKeyUp={handleAnpiTemp} value={anpi}
                                        onKeyPress={e => {
                                            const { key } = e;
                                            if(key === "Enter") {
                                                e.preventDefault()
                                                //droitsRef.current.focus()
                                            }
                                        }} //ref={dfdeRef}
                                        onChange={handleValue} placeholder="Anpi"/> */}
                                      <NumberFormat className="form-control"
                                            placeholder="anpi"
                                            name="anpi"
                                            value={anpi}
                                            onValueChange={(values) => {
                                                const  {value} = values;
                                                handleAnpiTemp(value)
                                            }}
                                            onChange={handleValue}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}/>  
                                </div>
                        
                                <div className="form-group col-md-4">
                                    <label htmlFor="Droit">DGI</label>
                                    {/* <input type="number" className="form-control" name="dgi"
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                        // penaliteRef.current.focus()
                                        }
                                    }} //ref={droitsRef}
                                            value={dgi} onKeyUp={handleDgiTemp} onChange={handleValue}
                                            placeholder="DGI" /> */}
                                         <NumberFormat className="form-control"
                                            placeholder="dgi"
                                            name="dgi"
                                            value={dgi}
                                            onValueChange={(values) => {
                                                const  {value} = values;
                                                handleDgiTemp(value)
                                            }}
                                            onChange={handleValue}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}/>
                                </div>
                                
                                <div className="form-group col-md-4">
                                    <label htmlFor="FraisGreffe">Greffe</label>
                                    {/* <input type="number" className="form-control" name="greffe"
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            //ogapiRef.current.focus()
                                        }
                                    }} //ref={greffeRef}
                                    value={greffe} onKeyUp={handleGreffeTemp}
                                    onChange={handleValue} placeholder="Frais de Greffe" /> */}

                                <NumberFormat className="form-control"
                                    placeholder="Frais de Greffe"
                                    name="greffe"
                                    value={greffe}
                                    onValueChange={(values) => {
                                       const  {value} = values;
                                       handleGreffeTemp(value)
                                    }}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>
                                </div>
                                <div className="form-group col-md-4">
                                <label htmlFor="FraisOgapi">Ogapi</label>  
                               {/*  <input type="number" className="form-control" name="ogapi"
                                        onKeyPress={e => {
                                            const { key } = e;
                                            if(key === "Enter") {
                                                e.preventDefault()
                                               // insertionJournalRef.current.focus()
                                            }
                                        }}// ref={ogapiRef}
                                        value={ogapi} onKeyUp={handleOgapiTemp}
                                        onChange={handleValue} placeholder="Frais Ogapi"/> */}
                                    <NumberFormat className="form-control"
                                        placeholder="Frais Ogapi"
                                        name="ogapi"
                                        value={ogapi}
                                        onValueChange={(values) => {
                                        const  {value} = values;
                                        handleOgapiTemp(value)
                                        }}
                                        onChange={handleValue}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="InsertionJournalOfficiel">JO</label>  
                                    {/* <input type="number" className="form-control" name="insertionJournal"
                                        //ref={insertionJournalRef} 
                                        onKeyUp={handleJournalTemp} value={insertionJournal}
                                        onChange={handleValue} placeholder="Insertion journal officiel"/> */}
                                    <NumberFormat className="form-control"
                                        placeholder="Insertion journal officiel"
                                        name="insertionJournal"
                                        value={insertionJournal}
                                        onValueChange={(values) => {
                                            const  {value} = values;
                                            handleJournalTemp(value)
                                        }}
                                        onChange={handleValue}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}/>
                                </div>  

                            
                        </div>
                        <br/>
                    
                        <br/>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="totalDGI">Frais</label>  
                                {/* <input type="number" disabled className="form-control" id="totalDGI" value={totalFrais} placeholder="Total"/> */}
                                <NumberFormat className="form-control"
                                    disabled
                                    placeholder="Insertion journal officiel"
                                    name="insertionJournal"
                                    value={totalFrais}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>
                            </div>
                        </div>
                        <button disabled={errors} type="submit" className="btn btn-primary">Enregistrer</button>
                </form>
  
            </Modal.Body>
                 
              </Modal>
        </Fragment>
      );
};

export default UpdateTresor;