import React, { useState, useContext, useEffect, useRef } from 'react';
import RecetteContext from "../context/RecetteContext"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import { v4 as uuidv4 } from 'uuid';
import NumberFormat from 'react-number-format';

const RecetteForm = () => {
    
    const recetteContext = useContext(RecetteContext)
    const { addRecette, recettes } = recetteContext
    const [startDate, setStartDate] = useState(new Date());
    const [totalDGI, setTotalDGI] = useState(0);
    const [recette, setRecette] = useState({
        id:"",
        dateComptable: startDate, quantite: "",
        ordre: "", formExpress: "",
        locationAutre: "", dfde: "",
        droits: "", penalite: "",
        timbres: "", total: "0",
        greffe: "", ogapi: "",
        insertionJournal: "",
        totalFinal: ""
    })
    const [errors, setErrors] = useState(false)
    const [droitsTemp, setDroitsTemp] = useState(0)
    const [penalTemp, setPenalTemp] = useState(0)
    const [timbreTemp, setTimbreTemp] = useState(0)

    const [totalLigne, setTotalLigne] = useState(0)
    const [expressTemp, setExpressTemp] = useState(0)
    const [locationTemp, setLocationTemp] = useState(0)
    const [dfdeTemp, setDfdeTemp] = useState(0)
    const [greffeTemp, setGreffeTemp] = useState(0)
    const [ogapiTemp, setOgapiTemp] = useState(0)
    const [journalTemp, setJournalTemp] = useState(0)
    const [colorQt, setcolorQt] = useState("none")
    const [colorOrdre, setColorOrdre] = useState("none")
    // Ref
    const qtRef = useRef(null)
    const inputOrdreRef = useRef(null)
    let formExpressRef = useRef(null)
    let locationAutreRef = useRef(null)
    let dfdeRef = useRef(null)
    let penaliteRef = useRef(null)
    let droitsRef = useRef(null);
 
    
    let timbreRef = useRef(null);

    let greffeRef = useRef(null);
    let ogapiRef = useRef(null);
    let insertionJournalRef = useRef(null);

    let {  
        id,
        dateComptable, quantite,
        ordre, formExpress,
        locationAutre, dfde,
        droits, penalite,
        timbres, total,
        greffe, ogapi,
        insertionJournal,
        totalFinal 
    } = recette

    const handleSubmit = (e) => {
        e.preventDefault();

        recette.id = uuidv4()
       
        recette.dateComptable = startDate;

        recette.droits = droitsTemp;
        recette.penalite = penalTemp;
        recette.timbres = timbreTemp;

        recette.total = totalDGI;

        recette.formExpress = expressTemp;
        recette.locationAutre = locationTemp;
        recette.dfde = dfdeTemp;
        recette.greffe = greffeTemp;
        recette.ogapi = ogapiTemp;
        recette.insertionJournal = journalTemp;
        recette.totalFinal = totalLigne;
        console.log(recette.dateComptable)


        let test = moment(recette.dateComptable).format();
        console.log(moment(test).toDate())

        addRecette(recette)

        setRecette({
            id:"",
            dateComptable: startDate, quantite: "",
            ordre: "", formExpress: "",
            locationAutre: "", dfde: "",
            droits: "", penalite: "",
            timbres: "", total: "0",
            greffe: "", ogapi: "",
            insertionJournal: "",
            totalFinal: ""
        })

        setTotalLigne(0)
        setTotalDGI(0)
        handleFocus()
    }

    const handleFocus = () => {
        qtRef.current.focus();
    }

    const handleValue = (e) => {
        setRecette({ ...recette, [e.target.name]: e.target.value})
    }

   const onChangeDate = (date) => {
        setStartDate(date)
   }

   const handleTotalDGI = () => {
        if(isNaN(droitsTemp)) {
            setDroitsTemp(0)
        }
        if(isNaN(penalTemp)) {
            setPenalTemp(0)
        }
        if(isNaN(timbreTemp)) {
            setTimbreTemp(0)
        }
       setTotalDGI(droitsTemp + penalTemp + timbreTemp)
   }

    const handleDroitTemp = (e) => {
        
        setDroitsTemp(parseInt(e))
    }

    const handlePenalTemp = (e) => {
        
      setPenalTemp(parseInt(e))
    }

    const handleTimbreTemp = (e) => {
        setTimbreTemp(parseInt(e))
    }

    // total ligne
    const handleexpressTemp = (e) => {
        
        //setExpressTemp(parseInt(e.target.value))
        setExpressTemp(parseInt(e))
        //check if number exist

    }

    const handleLocationTemp = (e) => {
        
        setLocationTemp(parseInt(e))
    }

    const handleDfdeTemp = (e) => {
        
        setDfdeTemp(parseInt(e))
    }

    const handleGreffeTemp = (e) => {
        
        setGreffeTemp(parseInt(e))
    }

    const handleOgapiTemp = (e) => {
        
        setOgapiTemp(parseInt(e))
    }

    const handleJournalTemp = (e) => {
        
        setJournalTemp(parseInt(e))
    }

    const handleTotalLigne = () => {
        if(isNaN(expressTemp)) {
            setExpressTemp(0)
        }
        if(isNaN(locationTemp)) {
            setLocationTemp(0)
        }
        if(isNaN(dfdeTemp)) {
            setDfdeTemp(0)
        }
        if(isNaN(droitsTemp)) {
            setDroitsTemp(0)
        }
        if(isNaN(penalTemp)) {
            setPenalTemp(0)
        }
        if(isNaN(greffeTemp)) {
            setGreffeTemp(0)
        }
        if(isNaN(ogapiTemp)) {
            setOgapiTemp(0)
        }
        if(isNaN(journalTemp)) {
            setJournalTemp(0)
        }
        if(isNaN(timbreTemp)) {
            setTimbreTemp(0)
        }

        setTotalLigne(expressTemp + locationTemp 
            + dfdeTemp + droitsTemp + penalTemp + greffeTemp
             + ogapiTemp + journalTemp + timbreTemp)
    }

    const handleQuantite = (e) => {
        var q = null;        
        q = recettes.find(recette => parseInt(recette.quantite) === parseInt(e.target.value)) 
        if(typeof q === 'undefined') {
            setcolorQt("white") 
            setErrors(false)
        } else {
            setcolorQt("red")
            setErrors(true)
        } 

    } 

    const handleOrdre = (e) => {
        var q = null;        
        q = recettes.find(recette => parseInt(recette.ordre) === parseInt(e.target.value)) 
        if(typeof q === 'undefined') {
            setColorOrdre("white") 
            setErrors(false)
        } else {
            setColorOrdre("red")
            setErrors(true)
        } 

    }
    

    useEffect(() => {
       handleTotalDGI()
       handleTotalLigne()
   }, [droitsTemp, penalTemp,
     expressTemp, locationTemp,
      dfdeTemp, greffeTemp,
    ogapiTemp, journalTemp, timbreTemp])
 
    return (
        <div>
            <form onSubmit={handleSubmit} >
            <div className="form-row" >
                        <div className="form-group col-md-4">
                            <label htmlFor="dateComptable">Date Comptable</label>
                            <br/>
                            <DatePicker  className="form-control" 
                                        selected={startDate}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={onChangeDate}  />
                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="NºQA">NºQA</label>  
                        <input type="number" className="form-control"
                                            required
                                            style={{ backgroundColor: colorQt}}
                                            name="quantite" onKeyUp={handleQuantite}
                                            onKeyPress={e => {
                                                const { key } = e;
                                                if(key === "Enter") {
                                                    e.preventDefault()
                                                    inputOrdreRef.current.focus()
                                                }
                                            }} ref={qtRef}
                                            value={quantite}
                                            onChange={handleValue} placeholder="NºQA"/>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="NºOR">NºOR</label>  
                            <input type="number" className="form-control"
                                                 style={{ backgroundColor: colorOrdre}}
                                                 name="ordre" onKeyUp={handleOrdre} required
                                                 value={ordre}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        formExpressRef.focus()
                                                    }
                                                }} ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="NºOR"/>
                        </div>
                </div>
                <br/>
                <h5>Recette anpi-gabon</h5>
                <br/>
                <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="FormuleExpress">Formule Express</label>
                             {/* <input type="number" className="form-control"
                                    name="formExpress" onKeyUp={handleexpressTemp}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            console.log("enter")
                                            locationAutreRef.current.focus()
                                        }
                                    }} ref={formExpressRef}
                                    value={formExpress} onChange={handleValue}
                                    placeholder="Formule Express" />  */}
                              <NumberFormat className="form-control"
                                   placeholder="Formule Express"
                                    name="formExpress"
                                    value={formExpress} 
                                    onValueChange={(values) => {
                                       const  {value} = values;
                                       handleexpressTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            locationAutreRef.focus()
                                        }
                                    }}
                                    ref={formExpressRef}
                                    getInputRef = {(el) => formExpressRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>        
                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="LocationAutres">Location et autres</label>  
                       {/*  <input type="number" className="form-control"
                                 onKeyPress={e => {
                                    const { key } = e;
                                    if(key === "Enter") {
                                        e.preventDefault()
                                        dfdeRef.current.focus()
                                    }
                                }} ref={locationAutreRef}
                                name="locationAutre" onKeyUp={handleLocationTemp}
                                value={locationAutre} onChange={handleValue} placeholder="Location et autres"/> */}

                                <NumberFormat className="form-control"
                                   placeholder="Formule Express"
                                    name="locationAutre"
                                    value={locationAutre} 
                                    onValueChange={(values) => {
                                       const  {value} = values;
                                       handleLocationTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            dfdeRef.focus()
                                        }
                                    }}
                                    ref={locationAutreRef}
                                    getInputRef = {(el) => locationAutreRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/> 

                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="dfde">Autre DFDE</label>  
                            {/* <input type="number" className="form-control"
                                   name="dfde" onKeyUp={handleDfdeTemp} value={dfde}
                                   onKeyPress={e => {
                                    const { key } = e;
                                    if(key === "Enter") {
                                        e.preventDefault()
                                        droitsRef.current.focus()
                                    }
                                }} ref={dfdeRef}
                                   onChange={handleValue} placeholder="Autre DFDE"/> */}

                                <NumberFormat className="form-control"
                                   placeholder="Autre DFDE"
                                    name="dfde"
                                    value={dfde} 
                                    onValueChange={(values) => {
                                       const  {value} = values;
                                       handleDfdeTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            droitsRef.focus()
                                        }
                                    }}
                                    ref={dfdeRef}
                                    getInputRef = {(el) => dfdeRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/> 
                        </div>
                </div>
                <br/>
                <h5>Droit d'enregistrement (DGI)</h5>
                <br/>
                <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="Droit">Droit (code 38)</label>
                            {/* <input type="number" className="form-control" name="droits"
                            onKeyPress={e => {
                                const { key } = e;
                                if(key === "Enter") {
                                    e.preventDefault()
                                    penaliteRef.current.focus()
                                }
                            }} ref={droitsRef}
                                    value={droits} onKeyUp={handleDroitTemp} onChange={handleValue}
                                    placeholder="Droit (code 38)" /> */}
                            <NumberFormat className="form-control"
                                placeholder="Droit (code 38)"
                                    name="droits"
                                    value={droits} 
                                    onValueChange={(values) => {
                                    const  {value} = values;
                                    handleDroitTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            penaliteRef.focus()
                                        }
                                    }}
                                    ref={droitsRef}
                                    getInputRef = {(el) => droitsRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>
                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="Penalite">Penalite (code 81)</label>  
                        {/* <input type="number" className="form-control" name="penalite"
                                onKeyPress={e => {
                                    const { key } = e;
                                    if(key === "Enter") {
                                        e.preventDefault()
                                        timbreRef.current.focus()
                                    }
                                }} ref={penaliteRef}
                               value={penalite} onKeyUp={handlePenalTemp} onChange={handleValue}
                               placeholder="Penalite (code 81)"/> */}

                            <NumberFormat className="form-control"
                                placeholder="Penalite (code 81)"
                                    name="penalite"
                                    value={penalite} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handlePenalTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            timbreRef.focus()
                                        }
                                    }}
                                    ref={penaliteRef}
                                    getInputRef = {(el) => penaliteRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>
                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="Timbre">Timbre (code 75)</label>  
                        {/* <input type="number" className="form-control" name="timbres"
                                onKeyPress={e => {
                                    const { key } = e;
                                    if(key === "Enter") {
                                        e.preventDefault()
                                        greffeRef.current.focus()
                                    }
                                }} ref={timbreRef}
                               value={timbres} onKeyUp={handleTimbreTemp}
                               onChange={handleValue} placeholder="Timbre (code 75)"/> */}
                        <NumberFormat className="form-control"
                                placeholder="Timbre (code 75)"
                                    name="timbres"
                                    value={timbres} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handleTimbreTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            greffeRef.focus()
                                        }
                                    }}
                                    ref={timbreRef}
                                    getInputRef = {(el) => timbreRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>

                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="totalDGI">Total DGI</label>  
                            {/* <input type="number" disabled className="form-control" id="totalDGI" value={totalDGI} placeholder="Total"/> */}
                            <NumberFormat className="form-control"
                            disabled
                            placeholder="Total"
                            value={totalDGI}
                            thousandSeparator={'.'}
                            decimalSeparator={','}/>
                        </div>
                </div>
                <br/>
               
                <br/>
                <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="FraisGreffe">Frais de Greffe</label>
                           {/*  <input type="number" className="form-control" name="greffe"
                            onKeyPress={e => {
                                const { key } = e;
                                if(key === "Enter") {
                                    e.preventDefault()
                                    ogapiRef.current.focus()
                                }
                            }} ref={greffeRef}
                             value={greffe} onKeyUp={handleGreffeTemp}
                              onChange={handleValue} placeholder="Frais de Greffe" /> */}

                            <NumberFormat className="form-control"
                                placeholder="Frais de Greffe"
                                    name="greffe"
                                    value={greffe} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handleGreffeTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            ogapiRef.focus()
                                        }
                                    }}
                                    ref={greffeRef}
                                    getInputRef = {(el) => greffeRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>
                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="FraisOgapi">Frais Ogapi</label>  
                        {/* <input type="number" className="form-control" name="ogapi"
                                onKeyPress={e => {
                                    const { key } = e;
                                    if(key === "Enter") {
                                        e.preventDefault()
                                        insertionJournalRef.current.focus()
                                    }
                                }} ref={ogapiRef}
                                value={ogapi} onKeyUp={handleOgapiTemp}
                                onChange={handleValue} placeholder="Frais Ogapi"/> */}

                            <NumberFormat className="form-control"
                                placeholder="Frais Ogapi"
                                    name="ogapi"
                                    value={ogapi} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handleOgapiTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            insertionJournalRef.focus()
                                        }
                                    }}
                                    ref={ogapiRef}
                                    getInputRef = {(el) => ogapiRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="InsertionJournalOfficiel">Insertion Journal Officiel</label>  
                            {/* <input type="number" className="form-control" name="insertionJournal"
                                   ref={insertionJournalRef} 
                                   onKeyUp={handleJournalTemp} value={insertionJournal}
                                   onChange={handleValue} placeholder="Insertion journal officiel"/> */}
                             <NumberFormat className="form-control"
                                    placeholder="Insertion journal officiel"
                                    name="insertionJournal"
                                    value={insertionJournal} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handleJournalTemp(value)
                                    }}
                                   
                                    ref={insertionJournalRef}
                                    getInputRef = {(el) => insertionJournalRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','} />

                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="total">Total</label>  
                            {/* <input type="number" disabled className="form-control" id="total" value={totalLigne} placeholder="Total"/> */}
                            <NumberFormat className="form-control"
                            disabled
                            placeholder="Total"
                            value={totalLigne}
                            thousandSeparator={'.'}
                            decimalSeparator={','}/>
                        
                        </div>
                </div>
                <button disabled={errors} type="submit" className="btn btn-primary">Enregistrer</button>
            </form>
        </div>
    );
};

export default RecetteForm;