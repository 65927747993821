import React, { Fragment } from 'react';
import RecetteJour from '../recettes/RecetteJour';
import EtatPartenaire from '../Etats/EtatPartenaire';

const RecettesPage = () => {
    return (
        <Fragment>
           
            <RecetteJour />
        </Fragment>
    );
};

export default RecettesPage;