import React from 'react';

const Landing = () => {
    return (
        <div className="container">
            <div className="jumbotron mt-5">
            <div className="col-sm-8 mx-auto">
                <h1 className="text-center">Bienvenu</h1>
            </div>
            </div>
        </div>
    );
};

export default Landing;