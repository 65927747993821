import React, { useContext, useEffect } from 'react';
import RecetteContext from '../context/RecetteContext';
import { Row, Col } from "react-bootstrap"
import JsfPdfDoc from '../Etats/JsPdfDoc';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../table.scss'
import ListSaisieItems from './ListSaisieItems';

const ListSaisie = (props) => {

  const recetteContext = useContext(RecetteContext);
  const { allFinances, finances } = recetteContext;

   useEffect(() => {
     allFinances();
   }, [])

    return (
        <div className="table-responsive-lg" style={{ marginTop: "10px"}}>
            <Row style={{ marginBottom:"10px"}}>  
                <Col>
                </Col>
                <Col>
                    <JsfPdfDoc source="vu-ensemble-finance" title="REPORTING BUREAU DES IMMATRICULATIONS"/>
                </Col>
                <Col>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-primary download-table-xls-button"
                        table="vu-ensemble-finance"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Telecharger excel"/>
                </Col>
            </Row>
            <div className="table-container">
            <table  id="vu-ensemble-finance" className="table table-bordered table-hover table-sm table-light">
                  <thead className="thead-light">
                    <tr>
                      <th class="col-xs-3" >#</th>
                      <th class="col-xs-3" >Date Comptable</th>
                      <th class="col-xs-3">IGU</th>
                      <th class="col-xs-3">NºDOSSIER</th>
                      <th class="col-xs-3">DENOMINATION SOCIALE</th>
                      <th class="col-xs-3">SIGLE</th>
                      <th class="col-xs-3">NOM ET PRENOM DIRIGEANT</th>
                      <th class="col-xs-3">FORME JURIDIQUE</th>
                      <th class="col-xs-3">NATIONALITE</th>
                      <th class="col-xs-3">NATURE OPERATION</th>
                      <th class="col-xs-3">PROCEDURE EXPRESS</th>
                      <th class="col-xs-3">NºORDRE DE RECETTE</th>
                      <th class="col-xs-3">FRAIS</th>
                      <th class="col-xs-3">SITUATION DU DOSSIER</th>
                      <th class="col-xs-3">ANPI</th>
                      <th class="col-xs-3">DGI</th>
                      <th class="col-xs-3">GREFFE</th>
                      <th class="col-xs-3">OGAPI</th>
                      <th class="col-xs-3">J.O</th>
                      <th class="col-xs-3">OBSERVATIONS</th>
                      {/* <th scope="col">Operations</th> */}
                    </tr>
                  </thead>
                  <tbody >
                      { finances.map((finance, i) => (
                        <ListSaisieItems finance={finance} numLigne={i+1} key={i} />
                      ))
                      }
                  </tbody>
              </table>
            </div>
        </div>
    );
};

export default ListSaisie;