import React, { useState, Fragment, useEffect } from 'react';
import { Table } from "react-bootstrap"
import Moment from 'react-moment';
import moment from 'moment';
const ShowTable = (props) => {

    const { choix, filterRecettes, listMontant } = props;
    const tableGlobal = []
    let dateInit = null;
    let somAnpi = 0;
    const [header, setHeader] = useState({
        recap_recettes: ["ANPI", "DGI", "GREFFE", "OGAPI", " J.O", "Recette globale", "Part Réelle ANPI GABON", "Reversement Hors ANPI"],
        all: ["DENOMINATION SOCIALE", "IGU", "NATURE", "N°ORDRE", "ANPI", "DGI", "GREFFE", "OGAPI", "J.O", "FRAIS (FCFA)"],
       
    })

    let total = 0;
    let tab = [];
    let filteredArr = [];

    const headerFun = (choix) => {
        switch (choix) {
            case "recap_recettes":
                return header.recap_recettes
                break;
            default:
                return header.all
                break;
        }
    }
    
    const recetteMoisJour = (table) => {
        if(table != null) {
         total = 0;
         for (let index = 0; index < table.length; index++) {
             total += table[index];
         }
         return total;
        } else {
            return 0;
        }
         
     }

     const customTable = () => {
       
        if(filterRecettes !== null) {
            filterRecettes.forEach((obj, index) => {
                let somAnpi = 0;
                let somDGI = 0;
                let somGreffe = 0;
                let somOgapi = 0;
                let somJo = 0;
                //console.log(`:::>${index} : ${obj.category} `)
                filterRecettes.forEach((obj2, index) => {
                  //console.log(`:x:>${index} : ${obj2.category} `)
                  if(obj.dateComptable === obj2.dateComptable) {
                    somAnpi += obj2.anpi;
                    somDGI += obj2.dgi;
                    somGreffe += obj2.greffe;
                    somOgapi += obj2.ogapi;
                    somJo += obj2.insertionJournal;
                    //console.log(`${obj.dateComptable} :  ${som}`)
                   }
                }) 
                tab.push({dateComptable: obj.dateComptable, montantAnpi: somAnpi,
                        montantDGI: somDGI, montantGreffe: somGreffe, montantOgapi: somOgapi,
                        montantJo: somJo })
              })
              //console.log(tab)
              filteredArr = tab.reduce((acc, current) => {
                const x = acc.find(item => item.dateComptable === current.dateComptable);
                if (!x) {
                  return acc.concat([current]);
                } else {
                  return acc;
                }
              }, []);
              
        }
        return filteredArr;
     }

     // Formate le montant avec les points
    const formatMontant = (montant) => {
        return new Intl.NumberFormat().format(montant)
    } 

    const detailLigne = (ligne) => {
        return ( 
            <Fragment>
                <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                <td>{ligne.denomination}</td>
                <td>{ligne.igu}</td>
                <td>{ligne.nature}</td>
                <td>{ligne.ordre}</td>
                <td>{formatMontant(ligne.anpi)}</td>
                <td>{formatMontant(ligne.dgi)}</td>
                <td>{formatMontant(ligne.greffe)}</td>
                <td>{formatMontant(ligne.ogapi)}</td>
                <td>{formatMontant(ligne.insertionJournal)}</td>
                <td>{formatMontant(ligne.frais)}</td>
            </Fragment>)
    } 

    const bodyFun = (ligne) => {
        switch (choix) {
            case "Flore":
                if(ligne.igu === "Flore"){
                   return detailLigne(ligne)
                }
                break;
            case "Lyvia":
                if(ligne.igu === "Lyvia"){
                    return detailLigne(ligne)
                }
                break;
            case "Oyila Simplice":
                if(ligne.igu === "Oyila Simplice"){
                    return detailLigne(ligne)
                }
                break;
            case "Eude":
                if(ligne.igu === "Eude"){
                    return detailLigne(ligne)
                }
                break;
            case "oyem":
                if(ligne.igu === "oyem"){
                    return detailLigne(ligne)
                }
                break;
            case "fcv":
                if(ligne.igu === "fcv"){
                    return detailLigne(ligne)
                }
                break;
            case "mla":
                if(ligne.igu === "mla"){
                    return detailLigne(ligne)
                }
                break;
            case "lbrn":
                if(ligne.igu === "lbrn"){
                    return detailLigne(ligne)
                }
                break;
            case "valerie":
                if(ligne.igu === "valerie"){
                    return detailLigne(ligne)
                }
                break;
            case "ogapi":
                if(ligne.igu === "ogapi"){
                    return detailLigne(ligne)
                }
                break;
            case "CR":
                if(ligne.nature === "CR") {
                   return detailLigne(ligne)
               }
                break;
            case "MOD":
                if(ligne.nature === "MOD") {
                    return detailLigne(ligne)
                }
                break;
            case "CES":
                if(ligne.nature === "CES") {
                    return detailLigne(ligne)
                }
                break;
            case "HAR":
                if(ligne.nature === "HAR") {
                    return detailLigne(ligne)
                }
                break;
            case "DUP":
                if(ligne.nature === "DUP") {
                    return detailLigne(ligne)
                }
                break;
            case "ACTES":
                if(ligne.nature === "REDACTION_DES_ACTES") {
                    return detailLigne(ligne)
                }
                break;
            case "AUTRE":
                if(ligne.nature === "AUTRE") {
                    return detailLigne(ligne)
                }
                break;
            case "recap_recettes":
                return (
                    <Fragment>
                        <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                        <td>{formatMontant(ligne.anpi)}</td>
                        <td>{formatMontant(ligne.dgi)}</td>
                        <td>{formatMontant(ligne.greffe)}</td>
                        <td>{formatMontant(ligne.ogapi)}</td>
                        <td>{formatMontant(ligne.insertionJournal)}</td>
                        <td>
                            {ligne.frais}
                        </td>
                        <td>
                        { formatMontant( Math.round( parseInt(ligne.anpi) * 0.75 
                        + parseInt(ligne.greffe) * 0.5 + parseInt(ligne.nbre_operation_ogapi) * 3500 +parseInt(ligne.insertionJournal) * (1/3)))}
                        </td>
                        <td>
                            {
                             formatMontant( Math.round( parseInt(ligne.frais) - (parseInt(ligne.anpi) * 0.75 
                              + parseInt(ligne.greffe) * 0.5 + parseInt(ligne.nbre_operation_ogapi) * 3500 + parseInt(ligne.insertionJournal) * (1/3))))  
                            }
                        </td>
                    </Fragment>
                )
                break;  
            default:
                return ( 
                    <Fragment>
                    <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                    <td>{ligne.denomination}</td>
                    <td>{ligne.igu}</td>
                    <td>{ligne.nature}</td>
                    <td>{ligne.ordre}</td>
                    <td>{formatMontant(ligne.anpi)}</td>
                    <td>{formatMontant(ligne.dgi)}</td>
                    <td>{formatMontant(ligne.greffe)}</td>
                    <td>{formatMontant(ligne.ogapi)}</td>
                    <td>{formatMontant(ligne.insertionJournal)}</td>
                    <td>{formatMontant(ligne.frais)}</td>
                </Fragment>)
                break;
        }
    }


    const finalMontantFun = () => {
        switch (choix) {
            case "recap_recettes":
                return (
                    <Fragment>
                        <td>Total</td>
                      { (listMontant !== null) && listMontant.map(montant => (
                            <td>{formatMontant(Math.round( recetteMoisJour(montant)))}</td>
                        ))} 
                    </Fragment>
                )
                break;  
            default:
                return ( 
                    <Fragment>
                    <td>Total</td>
                       <td>-</td>
                       <td>-</td>
                       <td>-</td>
                       <td>-</td>
                        { (listMontant !== null) && listMontant.map(montant => (
                            <td>{formatMontant(Math.round(recetteMoisJour(montant)))}</td>
                        ))} 
                </Fragment>)
                break;
        }
    }

    return (
        <div>
            
            <Table id="table-to-xls" striped bordered hover size="sm" variant="light">
                <thead className="thead-light">
                    <tr>
                        <th>#</th>
                        {
                            headerFun(choix).map(e => <th>{e}</th>)
                        }
                    </tr>
                </thead>
                <tbody>

                {
               
                (filterRecettes !== null)  && filterRecettes.map(ligne => {
                   return <tr>
                        
                        { bodyFun(ligne)}
                        
                    </tr>
                }) 
                
                }

                   <tr class="table-warning font-weight-bold">

                        { finalMontantFun() }
                   </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default ShowTable;