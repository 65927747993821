import React, { Fragment, useState } from 'react';
import RecetteContext from '../context/RecetteContext'
import { useContext } from 'react';
//import RecetteUpdate from './RecetteUpdate';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'


const RestantPartenairesItems = ({restant, numLigne}) => {

    const recetteContext = useContext(RecetteContext)
    const { deleteRestantPartenaire, setSelectedRecette } = recetteContext
    const [show, setShow] = useState(false)

    const { 
        id,
        dateComptable,
        versementJo,
        versementOgapi,
        versementGreffe,
        totalVersement,
        quoteR,
        restantR,
        total
    } = restant;

    const handleDelete = () => {
        deleteRestantPartenaire(id)
    }

    const handleUpdate = () => {
        console.log(restant)
        setSelectedRecette(restant)
        setShow(!show)
    }

    return (
        <Fragment>
            <tr>
                <th scope="row">{ numLigne }</th>
                <td><Moment format="MM/YYYY">{dateComptable}</Moment></td>
                <td>{new Intl.NumberFormat("de-DE").format(versementJo)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(versementOgapi)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(versementGreffe)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(totalVersement)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(quoteR)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(restantR)}</td>
                <td>
                    <button className="btn btn-danger" onClick={handleDelete}><FontAwesomeIcon icon={faTrashAlt} /></button>
                    {/* <button className="btn btn-primary" onClick={handleUpdate}><FontAwesomeIcon icon={faEdit} /></button> */}
                </td>
            </tr>
            {/* <RecetteUpdate onShow={show} change={handleUpdate}/> */}
        </Fragment>
    );
};

export default RestantPartenairesItems;