import React, { useState, Fragment, useEffect } from 'react';
import { Table } from "react-bootstrap"
import Moment from 'react-moment';
import moment from 'moment';
const ShowTableFinanceMois = (props) => {

    const { choix, filterRecettes, listMontant } = props;
    const tableGlobal = []
    let dateInit = null;
    let somAnpi = 0;
    let totalSom = 0;
    const [header, setHeader] = useState({
        recap_recette_caisse: [ "Recette globale", "Part Réelle ANPI GABON", "Reversement Hors ANPI"],
        recap_recettes: ["ANPI", "DGI", "GREFFE", "OGAPI", " J.O", "Recette globale", "Part Réelle ANPI GABON", "Reversement Hors ANPI"],
        recap_recette_caisse_total: ["Part ANPI-GABON", "Part DGI", "Part GREFFE", "Part OGAPI", "Part JO"],
        all: [""],
       
    })

    let total = 0;
    let tab = [];
    let filteredArr = [];

    const headerFun = (choix) => {
        switch (choix) {
            case "recap_recettes":
                return header.recap_recettes
                break;
            case "recap_recette_caisse":
                return header.recap_recette_caisse
                break;
            case "recap_recette_caisse_total":
                return header.recap_recette_caisse_total
                break;
            default:
                return header.all
                break;
        }
    }
    
    const recetteMoisJour = (table) => {
        if(table != null) {
         total = 0;
         for (let index = 0; index < table.length; index++) {
             total += table[index];
         }
         return total;
        } else {
            return 0;
        }
         
     }

     const customTable = () => {
       
        if(filterRecettes !== null) {
            filterRecettes.forEach((obj, index) => {
                let somAnpi = 0;
                let somDGI = 0;
                let somGreffe = 0;
                let somOgapi = 0;
                let somJo = 0;
                let somFoisOgapi = 0;
                //console.log(`:::>${index} : ${obj.category} `)
                filterRecettes.forEach((obj2, index) => {
                  //console.log(`:x:>${index} : ${obj2.category} `)
                  // Effectue la somme des montants
                  if(moment(obj.dateComptable).format('L')  === moment(obj2.dateComptable).format('L')) {
                    somAnpi += obj2.anpi;
                    somDGI += obj2.dgi;
                    somGreffe += obj2.greffe;
                    somOgapi += obj2.ogapi;
                    somFoisOgapi += obj2.nbre_operation_ogapi;
                    somJo += obj2.insertionJournal;
                    //console.log(`${obj.dateComptable} :  ${som}`)
                   }
                }) 
                tab.push({dateComptable: obj.dateComptable,denomination: obj.denomination, ordre: obj.ordre, montantAnpi: somAnpi,
                        montantDGI: somDGI, montantGreffe: somGreffe, montantOgapi: somOgapi, nbre_fois_ogapi: somFoisOgapi, 
                        montantJo: somJo })
              })
              console.log(tab)
              filteredArr = tab.reduce((acc, current) => {
                const x = acc.find(item => moment(item.dateComptable).format('L')  === moment(current.dateComptable).format('L'));
                //const x = acc.find(item => item.dateComptable  === current.dateComptable);
                if (!x) {
                    console.log(`current ${current.dateComptable}`)
                  return acc.concat([current]);
                } else {
                  return acc;
                }
              }, []);
              
        }
        return filteredArr;
        
        
     }

       // Formate le montant avec les points
    const formatMontant = (montant) => {
        return new Intl.NumberFormat().format(montant)
    } 


    const bodyFun = (data) => {
        switch (choix) {
            case "recap_recettes":
                return (
                    <Fragment>
                        <td><Moment format="DD/MM/YYYY">{data.dateComptable}</Moment></td>
                        <td>{formatMontant(data.montantAnpi)}</td>
                        <td>{formatMontant(data.montantDGI)}</td>
                        <td>{formatMontant(data.montantGreffe)}</td>
                        <td>{formatMontant(data.montantOgapi)}</td>
                        <td>{formatMontant(data.montantJo)}</td>
                        <td>{formatMontant( data.montantAnpi + data.montantDGI +  data.montantGreffe + data.montantOgapi + data.montantJo)}</td>
                        <td>{formatMontant( Math.round( data.montantAnpi * 0.75 + data.montantGreffe * 0.5 + data.nbre_fois_ogapi * 3500 + data.montantJo * (1/3)))}</td>
                        <td>{formatMontant(Math.round( data.montantAnpi + data.montantDGI +  data.montantGreffe + data.montantOgapi + data.montantJo - (data.montantAnpi * 0.75 + data.montantGreffe * 0.5 + data.nbre_fois_ogapi * 3500 + data.montantJo * (1/3))))}</td>
                    </Fragment>
                )
                break; 

            case "recap_recette_caisse_total":
                return (
                    <Fragment>
                        <td><Moment format="DD/MM/YYYY">{data.dateComptable}</Moment></td>
                        <td>{formatMontant(data.montantAnpi)}</td>
                        <td>{formatMontant(data.montantDGI)}</td>
                        <td>{formatMontant(data.montantGreffe)}</td>
                        <td>{formatMontant(data.montantOgapi)}</td>
                        <td>{formatMontant(data.montantJo)}</td>
                    </Fragment>
                )
                break; 
            case "recap_recette_caisse":
                return (
                    <Fragment>
                        <td><Moment format="DD/MM/YYYY">{data.dateComptable}</Moment></td>
                        <td>{formatMontant(data.montantAnpi + data.montantDGI +  data.montantGreffe + data.montantOgapi + data.montantJo)}</td>
                        <td>{formatMontant( Math.round( data.montantAnpi * 0.75 + data.montantGreffe * 0.5 + data.nbre_fois_ogapi * 3500 + data.montantJo * (1/3)))}</td>
                        <td>{formatMontant( Math.round( data.montantAnpi + data.montantDGI +  data.montantGreffe + data.montantOgapi + data.montantJo - (data.montantAnpi * 0.75 + data.montantGreffe * 0.5 + data.nbre_fois_ogapi * 3500 +   data.montantJo * (1/3))))}</td>
                    </Fragment>
                )
                break;  
            default:
                return ( 
                    <Fragment>
                    {/* <td><Moment format="DD/MM/YYYY">{data.dateComptable}</Moment></td>
                    <td>{data.denomination}</td>
                    <td>{data.ordre}</td>
                    <td>{data.anpi}</td>
                    <td>{data.dgi}</td>
                    <td>{data.greffe}</td>
                    <td>{data.ogapi}</td>
                    <td>{data.insertionJournal}</td>
                    <td>{data.frais}</td> */}
                </Fragment>)
                break;
        }
    }


    const finalMontantFun = () => {
        switch (choix) {
            case "recap_recettes":
                return (
                    <Fragment>
                        <td>Total</td>
                      { (listMontant !== null) && listMontant.map(montant => (
                            <td>{formatMontant(Math.round( recetteMoisJour(montant)))}</td>
                        ))} 
                    </Fragment>
                )
                break;  
            case "recap_recette_caisse":
                return (
                    <Fragment>
                        <td>Total</td>
                        { (listMontant !== null) && listMontant.map(montant => (
                            <td>{ formatMontant(Math.round(recetteMoisJour(montant)))}</td>
                        ))} 
                       
                    </Fragment>
                )
                break; 
            case "recap_recette_caisse_total":
               
                return (
                    <Fragment>
                        <td>Total</td>
                        { (listMontant !== null) && listMontant.map(montant => (
                            <td>{formatMontant( recetteMoisJour(montant))}</td>
                        ))} 
                        
                    </Fragment>
                )
                break; 
            default:
                return ( 
                    <Fragment>
                   {/*  <td>Total</td>
                       <td>-</td>
                       <td>-</td>
                        { (listMontant !== null) && listMontant.map(montant => (
                            <td>{ recetteMoisJour(montant)}</td>
                        ))}  */}
                   
                </Fragment>)
                break;
        }
    }
    return (
        <div>
            
            <Table id="table-to-xls" striped bordered hover size="sm" variant="light">
                <thead className="thead-light">
                    <tr>
                        <th>#</th>
                        {
                            headerFun(choix).map(e => <th>{e}</th>)
                        }
                    </tr>
                </thead>
                <tbody>

                {
                  
                  (customTable() !== null) && customTable().map(data => 
                    (
                    <tr>
                        {bodyFun(data)}
                        
                    </tr>)
                )
                
                }

                   <tr class="table-warning font-weight-bold">

                        { finalMontantFun() }
                       
                   </tr>
                   { choix === "recap_recette_caisse_total" ? <tr>
                       <td>Total : </td>
                       
                        {formatMontant( Math.round( totalSom = listMontant.map(montant => (
                            recetteMoisJour(montant)
                        )).reduce((som, val) => som + val)))} 
                   </tr> : null}
                </tbody>
            </Table>
        </div>
    );
};

export default ShowTableFinanceMois;