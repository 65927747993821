import React, { Fragment, useState } from 'react';
import RecetteContext from '../context/RecetteContext'
import { useContext } from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import UpdateTresor from './updateTresor'


const ListSaisieItems = ({finance, numLigne}) => {

    const recetteContext = useContext(RecetteContext)
    const { deleteFinance, setSelectedFinance } = recetteContext
    const [show, setShow] = useState(false)

    const { 
        id,
        dateComptable, igu,
        dossier, denomination, sigle, dirigeant, forme_juridique,
        nationalite, nature, procedure_express, frais,  anpi, dgi, greffe, situation_dossier, observation,
        ordre,
        ogapi,
        insertionJournal
    } = finance;

    const handleDelete = () => {
        deleteFinance(id)
    }

    const handleUpdate = () => {
        console.log(finance)
        setSelectedFinance(finance)
        setShow(!show)
    }

    return (
        <Fragment>
            <tr>
                <th class="col-xs-3">{ numLigne }</th>
                <td class="col-xs-3"><Moment format="DD/MM/YYYY">{dateComptable}</Moment></td>
                <td class="col-xs-3"> {igu}</td>
                <td class="col-xs-3">{dossier}</td>
                <td class="col-xs-3">{denomination}</td>
                <td class="col-xs-3">{sigle}</td>
                <td class="col-xs-3">{dirigeant}</td>
                <td class="col-xs-3">{forme_juridique}</td>
                <td class="col-xs-3">{nationalite}</td>
                <td class="col-xs-3">{nature}</td>
                <td class="col-xs-3">{procedure_express}</td>
                <td class="col-xs-3">{ordre}</td>
                <td class="col-xs-3">{new Intl.NumberFormat().format(frais)}</td>

                <td class="col-xs-3">{situation_dossier}</td>
                <td class="col-xs-3">{new Intl.NumberFormat().format(anpi)}</td>
                <td class="col-xs-3">{new Intl.NumberFormat().format(dgi)}</td>
                <td class="col-xs-3">{new Intl.NumberFormat().format(greffe)}</td>
                <td class="col-xs-3">{new Intl.NumberFormat().format(ogapi)}</td>
                <td class="col-xs-3">{new Intl.NumberFormat().format(insertionJournal)}</td>
                <td class="col-xs-3">{observation}</td>
                <td>
                    <button className="btn btn-danger" onClick={handleDelete}><FontAwesomeIcon icon={faTrashAlt} /></button>
                    <button className="btn btn-primary" onClick={handleUpdate}><FontAwesomeIcon icon={faEdit} /></button>
                </td>
            </tr>
             <UpdateTresor onShow={show} change={handleUpdate}/>
        </Fragment>
    );
};

export default ListSaisieItems;