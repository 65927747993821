/* 
export const showTable = () => {
    switch (selectPartner) {

      case "journal":

         return ( <Table id="table-to-xls" striped bordered hover size="sm" variant="light">
              <thead className="thead-light">
                  <tr>
                      <th>#</th>
                      <th>Insertion Journal Officiel</th>
                  </tr>
              </thead>
              <tbody>

              { (filterRecettes !== null) && filterRecettes.map(ligne => (
                      <tr>
                          <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                          <td>{ligne.insertionJournal}</td>
                      </tr>
                  ))}

                  <tr class="table-warning font-weight-bold">
                      <td>Total mois</td>
                      <td>{recetteMoisOf(montantInsertionJournalMois)}</td>
                  </tr>
              </tbody>
          </Table>)
          break;

      case "ogapi":

         return ( <Table id="table-to-xls" striped bordered hover size="sm" variant="light">
              <thead className="thead-light">
                  <tr>
                      <th>#</th>
                      <th>Frais Ogapi</th>
                  </tr>
              </thead>
              <tbody>

              { (filterRecettes !== null) && filterRecettes.map(ligne => (
                      <tr>
                          <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                          <td>{ligne.ogapi}</td>
                      </tr>
                  ))}

                  <tr class="table-warning font-weight-bold">
                      <td>Total mois</td>
                      <td>{recetteMoisOf(montantOgapiMois)}</td>
                  </tr>
              </tbody>
          </Table>)
          break;

      case "greffe":

         return ( <Table id="table-to-xls" striped bordered hover size="sm" variant="light">
              <thead className="thead-light">
                  <tr>
                      <th>#</th>
                      <th>Frais Greffe</th>
                  </tr>
              </thead>
              <tbody>

              { (filterRecettes !== null) && filterRecettes.map(ligne => (
                      <tr>
                          <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                          <td>{ligne.greffe}</td>
                      </tr>
                  ))}

                  <tr class="table-warning font-weight-bold">
                      <td>Total mois</td>
                      <td>{recetteMoisOf(montantGreffeMois)}</td>
                  </tr>
              </tbody>
          </Table>)
          break;

        case "dgi":

         return ( <Table id="table-to-xls" striped bordered hover size="sm" variant="light">
              <thead className="thead-light">
                  <tr>
                      <th>#</th>
                      <th>Droits</th>
                      <th>Penalite</th>
                      <th>Timbres</th>
                      <th>Total DGI</th>
                  </tr>
              </thead>
              <tbody>

              { (filterRecettes !== null) && filterRecettes.map(ligne => (
                      <tr>
                          <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                          <td>{ligne.droits}</td>
                          <td>{ligne.penalite}</td>
                          <td>{ligne.timbres}</td>
                          <td>{ligne.total}</td>
                      </tr>
                  ))}

                  <tr class="table-warning font-weight-bold">
                      <td>Total mois</td>
                      <td>{recetteMoisOf(montantDroitsMois)}</td>
                      <td>{recetteMoisOf(montantPenaliteMois)}</td>
                      <td>{recetteMoisOf(montantTimbreMois)}</td>
                      <td>{recetteMoisOf(montantDGIMois)}</td>
                  </tr>
              </tbody>
          </Table>)
          break;
        case "anpi":
          return ( <Table id="table-to-xls" striped bordered hover size="sm" variant="light">
                  <thead className="thead-light">
                      <tr>
                          <th>#</th>
                          <th>Formule Express</th>
                          <th>Location et autre</th>
                          <th>Autre DFDE</th>
                          <th>Total</th>
                      </tr>
                  </thead>
                  <tbody>

                  { (filterRecettes !== null) && filterRecettes.map(ligne => (
                          <tr>
                              <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                              <td>{ligne.formExpress}</td>
                              <td>{ligne.locationAutre}</td>
                              <td>{ligne.dfde}</td>
                              <td>{ligne.totalFinal}</td>
                          </tr>
                      ))}

                      <tr class="table-warning font-weight-bold">
                          <td>Total mois</td>
                          <td>{recetteMoisOf(montantExpressMois)}</td>
                          <td>{recetteMoisOf(montantLocationMois)}</td>
                          <td>{recetteMoisOf(montantDfdeMois)}</td>
                          <td>{recetteMoisOf(montantRecetteMois)}</td>
                      </tr>
                  </tbody>
              </Table>)
              break;
    
        default:
          return (
              <Table id="table-to-xls" striped bordered hover size="sm" variant="light">
              <thead className="thead-light">
                  <tr>
                      <th>#</th>
                      <th>Formule Express</th>
                      <th>Location et autre</th>
                      <th>Autre DFDE</th>
                      <th>Droits</th>
                      <th>Penalite</th>
                      <th>Timbres</th>
                      <th>Total DGI</th>
                      <th>Frais Greffe</th>
                      <th>Frais Ogapi</th>
                      <th>Insertion Journal Officiel</th>
                      <th>Total</th>
                  </tr>
              </thead>
              <tbody>

              { (filterRecettes !== null) && filterRecettes.map(ligne => (
                      <tr>
                          <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                          <td>{ligne.formExpress}</td>
                          <td>{ligne.locationAutre}</td>
                          <td>{ligne.dfde}</td>
                          <td>{ligne.droits}</td>
                          <td>{ligne.penalite}</td>
                          <td>{ligne.timbres}</td>
                          <td>{ligne.total}</td>
                          <td>{ligne.greffe}</td>
                          <td>{ligne.ogapi}</td>
                          <td>{ligne.insertionJournal}</td>
                          <td>{ligne.totalFinal}</td>
                      </tr>
                  ))}

                  <tr class="table-warning font-weight-bold">
                      <td>Total mois</td>
                      <td>{recetteMoisOf(montantExpressMois)}</td>
                      <td>{recetteMoisOf(montantLocationMois)}</td>
                      <td>{recetteMoisOf(montantDfdeMois)}</td>
                      <td>{recetteMoisOf(montantDroitsMois)}</td>
                      <td>{recetteMoisOf(montantPenaliteMois)}</td>
                      <td>{recetteMoisOf(montantTimbreMois)}</td>
                      <td>{recetteMoisOf(montantDGIMois)}</td>
                      <td>{recetteMoisOf(montantGreffeMois)}</td>
                      <td>{recetteMoisOf(montantOgapiMois)}</td>
                      <td>{recetteMoisOf(montantInsertionJournalMois)}</td>
                      <td>{recetteMoisOf(montantRecetteMois)}</td>
                  </tr>
              </tbody>
          </Table>
          )
            break;
    }
   
    
} */

export const recetteMoisOf = (table) => {
    if(table != null) {
     let total = 0;
     for (let index = 0; index < table.length; index++) {
         total += table[index];
     }
     return total;
    } else {
        return 0;
    }
     
 }


