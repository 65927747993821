import React, { useState, Fragment } from 'react';
import { Table } from "react-bootstrap"
import Moment from 'react-moment';
const ShowTable = (props) => {

    const { choix, filterRecettes, listMontant } = props;

    const [header, setHeader] = useState({
        journal: ["Insertion Journal Officiel"],
        ogapi: ["Frais Ogapi"],
        greffe: ["Frais Greffe"],
        dgi: [ "Droits", "Penalites", "Timbres", "Total DGI" ],
        droits: ["Droits"],
        penalites: ["Penalites"],
        timbres: ["Timbres"],
        anpi: [  "Formule Express", "Location et autre", "Autre DFDE", "Total"],
        allWithoutAnpi: ["Total DGI", "Frais Greffe", "Frais Ogapi",
            "Insertion Journal Officiel", "Total Partenaires"],
        restantPartenaire: ["Versement", "Quote-part ANPI", "Total partenaires", "Restant partenaires"],
        etat: ["Suivi individualisé Etat"],
        allFinance: ["DENOMINATION SOCIALE", "N°ORDRE", "FRAIS (FCFA)", "ANPI", "DGI", "GREFFE", "OGAPI", " J.O"],
        all: ["Formule Express", "Location et autre", "Autre DFDE", "Droits",
            "Penalite", "Timbres", "Total DGI", "Frais Greffe", "Frais Ogapi",
            "Insertion Journal Officiel", "Total"]
    })

    const headerFun = (choix) => {
        switch (choix) {
            case "journal":
                return header.journal
                break;
            case "ogapi":
                return header.ogapi
                break;
            case "greffe":
                return header.greffe
                break;
            case "dgi":
                return header.dgi
                break;
            case "droits":
                return header.droits
                break;
            case "penalites":
                return header.penalites
                break;
            case "timbres":
                return header.timbres
                break;
            case "anpi":
                return header.anpi
                break;
            case "tout-sans-anpi":
                return header.allWithoutAnpi
                break;
            case "restant-partenaire":
                return header.restantPartenaire
                break;
            case "etat":
                return header.etat
                break;
            case "allFinance":
                return header.allFinance
                break;
            default:
                return header.all
                break;
        }
    }
    let total = 0;
    const recetteMoisJour = (table) => {
        if(table != null) {
         total = 0;
         for (let index = 0; index < table.length; index++) {
             total += table[index];
         }
         return total;
        } else {
            return 0;
        }
         
     }

    const bodyFun = (ligne) => {
        switch (choix) {
            case "journal":
                return ( 
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.insertionJournal)}</td>
                    </Fragment>)
                break;
            case "ogapi":
                return ( 
                <Fragment>
                    <td>{new Intl.NumberFormat("de-DE").format(ligne.ogapi)}</td>
                </Fragment>)
                break;
            case "greffe":
                return ( 
                    <Fragment>
                         <td>{new Intl.NumberFormat("de-DE").format(ligne.greffe)}</td>
                    </Fragment>)
                break;
            case "dgi":
                return ( 
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.droits)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.penalite)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.timbres)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.total)}</td>
                    </Fragment>)
                break;
            case "droits":
                return (
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.droits)}</td>
                    </Fragment>
                )
                break;
            case "penalites":
                return (
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.penalite)}</td>
                    </Fragment>
                )
                break;
            case "timbres":
                return (
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.timbres)}</td>
                    </Fragment>
                )
            case "anpi":
                return ( 
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.formExpress)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.locationAutre)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.dfde)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.totalFinal)}</td>
                    </Fragment>)
                break;
            case "tout-sans-anpi":
                return (
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.total)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.greffe)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.ogapi)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.insertionJournal)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.total + ligne.greffe + ligne.ogapi + ligne.insertionJournal)}</td>
                    </Fragment>
                )
            case "restant-partenaire":
                return (
                    <Fragment>

                    </Fragment>
                )
            case "Suivi individualisé Etat":
                return (
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.dfde)}</td>
                    </Fragment>
                )
                break;  
            case "allFinance":
                return (
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.denomination)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.ordre)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.frais)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.anpi)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.dgi)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.greffe)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.ogapi)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.insertionJournal)}</td>
                    </Fragment>
                )
                break;
            default:
                return ( 
                    <Fragment>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.formExpress)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.locationAutre)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.dfde)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.droits)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.penalite)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.timbres)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.total)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.greffe)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.ogapi)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.insertionJournal)}</td>
                        <td>{new Intl.NumberFormat("de-DE").format(ligne.totalFinal)}</td>
                    </Fragment>)
                break;
        }
    }

    return (
        <div>
            
            <Table id="table-to-xls" striped bordered hover size="sm" variant="light">
                <thead className="thead-light">
                    <tr>
                        <th>#</th>
                       
                        {
                        headerFun(choix).map(e => <th>{e}</th>)
                        }
                    </tr>
                </thead>
                <tbody>

                { (filterRecettes !== null) && (choix !== "etat") && filterRecettes.map(ligne => (
                        <tr>
                            <td><Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment></td>
                            {bodyFun(ligne)}
                            
                        </tr>
                    ))}

                   <tr class="table-warning font-weight-bold">
                       <td>Total mois</td>
                        { (listMontant !== null) && listMontant.map(montant => (
                            <td>{choix === "etat" ? new Intl.NumberFormat("de-DE").format(recetteMoisJour(montant)) * 0.25 : new Intl.NumberFormat("de-DE").format(recetteMoisJour(montant))}</td>
                        ))} 

                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default ShowTable;