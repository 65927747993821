import React, { useState, useContext, useEffect } from 'react';
import RecetteContext from "../context/RecetteContext"
import DatePicker, { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr'
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ShowTableFinance from './ShowTableFinance';
import JsfPdfDoc from '../Etats/JsPdfDoc';

const FinanceJour = () => {

    registerLocale("fr", fr);
    const recetteContext = useContext(RecetteContext)
    const {
        filterFinances, montantFinanceAnpiJour, financeAnpiJour,
        montantFinanceDGIJour,financeDGIJour, montantFinanceGreffeJour,
        financeGreffeJour, montantFinanceOgapiJour, financeOgapiJour,  montantFinanceInsertionJournalJour,
        financeInsertionJournalJour, montantFraisJour,financeFraisJour,
        montantFinanceAnpiReelJour, financeAnpiReelJour, montantReversementEtat, reversementEtatJour,
        allFinancesByDate, allFinances, allFinanceBetweenDate,
    } = recetteContext;
    const [startDate, setStartDate] = useState(new Date());
    const [lastDate, setLastDate] = useState(new Date());
    const [selectPartner, setSelectPartner] = useState();
    const [listMontant, setListMontant] = useState(null);

    useEffect(() => {
        //allFinancesByDate(startDate)
        allFinanceBetweenDate(startDate, lastDate)
        financeDGIJour(startDate, lastDate, selectPartner)
        financeAnpiJour(startDate, lastDate, selectPartner)
        financeGreffeJour(startDate, lastDate, selectPartner)
        financeOgapiJour(startDate, lastDate, selectPartner)
        financeInsertionJournalJour(startDate, lastDate, selectPartner)
        financeFraisJour(startDate, lastDate, selectPartner)
        financeAnpiReelJour(startDate, lastDate, selectPartner)
        reversementEtatJour(startDate, lastDate, selectPartner)
    }, [startDate, lastDate, selectPartner])


    useEffect(() => {
       allFinances()
    }, [])

    useEffect(() => {
        handleMontantPartner()
    }, [filterFinances, selectPartner])

    const onChangeDate = (date) => {
        setStartDate(date)
    }

    const onChangeLastDate = (date) => {
        setLastDate(date)
    }
    
    const handlePartner = (e) => {
    setSelectPartner(e.target.value)
    }

    const handleMontantPartner = () => {
       
        switch (selectPartner) {
            
            case "recap_recettes":
                setListMontant([montantFinanceAnpiJour ,montantFinanceDGIJour,
                    montantFinanceGreffeJour, montantFinanceOgapiJour, montantFinanceInsertionJournalJour,
                    montantFraisJour ,montantFinanceAnpiReelJour, montantReversementEtat])
                break;
            case "cr":
                setListMontant([montantFinanceAnpiJour ,montantFinanceDGIJour,
                    montantFinanceGreffeJour, montantFinanceOgapiJour, montantFinanceInsertionJournalJour, montantFraisJour])
                break;
            default:
                setListMontant([montantFinanceAnpiJour ,montantFinanceDGIJour,
                    montantFinanceGreffeJour, montantFinanceOgapiJour, montantFinanceInsertionJournalJour, montantFraisJour])
                break;
        }
    }

    return (
        <div>
            <div style={{ margin: "20px"}}>
               <h1 className="display-4">
                    Suivie de finance par jour
               </h1>
            </div>

            <Row>
                <Col>
                    <div style={{ margin: "20px"}}>
                    
                        <label>Selectionner la date : </label>{"  "}
                        <br />
                        <DatePicker className="form-control" 
                                    selected={startDate}
                                    locale="fr"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={onChangeDate}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={lastDate}
                                    /> 
                        <br/> <br/>        
                        <DatePicker className="form-control" 
                            selected={lastDate}
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                            onChange={onChangeLastDate}
                            selectsEnd
                            startDate={startDate}
                            endDate={lastDate}
                            minDate={startDate}
                            /> 
                    </div>
                </Col>

                <Col>
                    <div style={{ margin: "20px"}}>
                        
                        <label for="selectPartenaires">Filtre : </label>{"  "}
                        <select class="form-control" id="selectPartenaires" value={selectPartner} onChange={handlePartner}>
                            <option>----</option>
                            <option value="allFinance">Tout</option>
                            <option value="recap_recettes">Récapitulatif recettes</option>
                            <option value="CR">--CR</option>
                            <option value="MOD">--MOD</option>
                            <option value="CES">--CES</option>
                            <option value="HAR">--HAR</option>
                            <option value="DUP">--DUP</option>
                            <option value="ACTES">REDACTION DES ACTES</option>
                            <option >IGU</option>
                            <option value="Flore">--Flore</option>
                            <option value="Lyvia">--Lyvia</option>
                            <option value="Oyila Simplice">--Oyila Simplice</option>
                            <option value="Eude">--Eude</option>
                            <option value="oyem">--Oyem</option>
                            <option value="fcv">--FCV</option>
                            <option value="mla">--MLA</option>
                            <option value="lbrn">--LBRN</option>
                            <option value="valerie">--Valerie</option>
                            <option value="ogapi">--Ogapi</option>
                            <option value="AUTRE">AUTRE</option>
                        </select>
                    </div>
                </Col>
                <Col>
                    <div style={{ margin: "20px"}}>
                        <label>Options : </label>
                        <Row>
                            <Col>
                                <JsfPdfDoc source="table-to-xls" title="SUIVI DES RECETTES"/>
                            </Col>
                            <Col>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="btn btn-primary download-table-xls-button"
                                    table="table-to-xls"
                                    filename="tablexls"
                                    sheet="tablexls"
                                    buttonText="Telecharger excel"/>
                            </Col>
                        </Row>
                    </div>
                    
                </Col>
            </Row>
           
    
             <ShowTableFinance choix={selectPartner} filterRecettes={filterFinances} listMontant={listMontant}/>
        </div>
    );
};

export default FinanceJour;