import React, { useEffect, useContext } from 'react';
import RecetteContext from "../context/RecetteContext"
import RestantPartenairesItems from "./RestantPartenairesItems"

const RestantPartenaires = ({dateCompt}) => {
   
    const recetteContext = useContext(RecetteContext);
    const {restantPartenaires, allRestantPartenaires ,allRestantPartenairesByMonth, filterRestantPartenaires } = recetteContext

  useEffect(() => {
    allRestantPartenaires()
  }, [])

  useEffect(() => {
    allRestantPartenairesByMonth(dateCompt);
    console.log(filterRestantPartenaires)
  }, [dateCompt, restantPartenaires])

    return (
        <div>
           <table  id="table-to-partners" className="table table-bordered table-hover table-sm table-light">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date Comptable</th>
                    <th scope="col">VersementJo</th>
                    <th scope="col">VersementOgapi</th>
                    <th scope="col">VersementGreffe</th>
                    <th scope="col">Total Versement</th>
                    <th scope="col">Quote-part</th>
                    <th scope="col">Restant</th>
                    {/* <th scope="col">Operations</th> */}
                  </tr>
                </thead>
                <tbody>
                     { filterRestantPartenaires.map((restant, i) => (
                      <RestantPartenairesItems restant={restant} numLigne={i+1} key={i} />
                    ))
                    } 
                </tbody>
            </table>
        </div>
    );
};

export default RestantPartenaires;