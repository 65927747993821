import React, { useState, useContext, useEffect } from 'react';
import RecetteContext from "../context/RecetteContext"
import DatePicker, { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr'
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ShowTableFinanceMois from './ShowTableFinanceMois';
import JsfPdfDoc from '../Etats/JsPdfDoc';

const FinanceMois = () => {

    registerLocale("fr", fr);
    const recetteContext = useContext(RecetteContext)
    const {
        filterFinances, montantFinanceAnpiJour, montantFinanceAnpiMois, financeAnpiMois, financeAnpiJour,
        financeDGIJour, montantFinanceDGIJour, montantFinanceGlobalAnpiMois, financeAnpiGlobalMois,
        montantFinanceDGIMois,financeDGIMois, financeGreffeJour, montantFinanceGreffeJour, montantFinanceGreffeMois,
        financeGreffeMois, financeOgapiJour, montantFinanceOgapiJour,  montantFinanceOgapiMois, financeOgapiMois, financeInsertionJournalJour,
        montantFinanceInsertionJournalJour, montantFinanceInsertionJournalMois,
        financeInsertionJournalMois, montantFraisMois, montantFraisJour, financeFraisJour, financeFraisMois,
        financeAnpiReelJour, montantFinanceAnpiReelJour, montantFinanceAnpiReelMois, financeAnpiReelMois, reversementEtatJour,  montantReversementEtatMois,
        reversementEtatMois, montantReversementEtat,
        allFinancesByMonth,allFinancesBetweenMonth, allFinanceBetweenDate, allFinances,
    } = recetteContext;
    const [startDate, setStartDate] = useState(new Date());
    const [lastDate, setLastDate] = useState(new Date());
    const [selectPartner, setSelectPartner] = useState();
    const [listMontant, setListMontant] = useState(null);
    const [listJourMontantMois, setListJourMontantMois] = useState(null);
    useEffect(() => {
        //allFinancesByMonth(startDate)
        //allFinancesBetweenMonth(startDate, lastDate)
        allFinanceBetweenDate(startDate, lastDate)
        //Pour avoir les montants total
        //financeAnpiMois(startDate, lastDate)
        financeAnpiJour(startDate, lastDate, selectPartner)
        financeDGIJour(startDate, lastDate, selectPartner)
        financeGreffeJour(startDate, lastDate, selectPartner)
        financeOgapiJour(startDate, lastDate, selectPartner)
        financeInsertionJournalJour(startDate, lastDate, selectPartner)
        financeFraisJour(startDate, lastDate, selectPartner)
        financeAnpiReelJour(startDate, lastDate, selectPartner)
        reversementEtatJour(startDate, lastDate, selectPartner)

        //financeDGIMois(startDate, lastDate)
        //financeGreffeMois(startDate, lastDate)
        //financeOgapiMois(startDate, lastDate)
        //financeInsertionJournalMois(startDate, lastDate)
        //financeFraisMois(startDate, lastDate)
        //financeAnpiReelMois(startDate, lastDate)
        //reversementEtatMois(startDate, lastDate)
        financeAnpiGlobalMois(startDate)
    }, [startDate, lastDate, selectPartner])

    useEffect(() => {
       allFinances()
    }, [])

    useEffect(() => {
        handleMontantPartner()
    }, [filterFinances, selectPartner])

    const onChangeDate = (date) => {
        setStartDate(date)
    }

    const onChangeLastDate = (date) => {
        setLastDate(date)
    }
    
    const handlePartner = (e) => {
    setSelectPartner(e.target.value)
    }

    // Montant total
    const handleMontantPartner = () => {
       
        switch (selectPartner) {
            
            case "recap_recettes":
                setListMontant([montantFinanceAnpiJour ,montantFinanceDGIJour,
                    montantFinanceGreffeJour, montantFinanceOgapiJour, montantFinanceInsertionJournalJour,
                    montantFraisJour, montantFinanceAnpiReelJour, montantReversementEtat])
                break;

            case "recap_recette_caisse_total":
                setListMontant([montantFinanceAnpiJour ,montantFinanceDGIJour,
                    montantFinanceGreffeJour, montantFinanceOgapiJour, montantFinanceInsertionJournalJour,
                    ])
                break;
            case "recap_recette_caisse":
                setListMontant([montantFraisJour, montantFinanceAnpiReelJour, montantReversementEtat])
                break;
            default:
                setListMontant([montantFinanceAnpiJour ,montantFinanceDGIJour,
                    montantFinanceGreffeJour, montantFinanceOgapiJour, montantFinanceInsertionJournalJour, montantFraisJour])
                break;
        }
    }

    return (
        <div>
            <div style={{ margin: "20px"}}>
               <h1 className="display-4">
                    Suivie de finance par Mois
               </h1>
            </div>

            <Row>
                <Col>
                    <div style={{ margin: "20px"}}>
                    
                        <label>Selectionner la date : </label>{"  "}
                        <br />
                        <DatePicker className="form-control" 
                                    selected={startDate}
                                    locale="fr"
                                    dateFormat="dd/MM/yyyy"
                                    //showMonthYearPicker
                                    //showFullMonthYearPicker
                                    onChange={onChangeDate}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={lastDate}
                                    /> 
                        <br/>
                        <DatePicker className="form-control" 
                                    selected={lastDate}
                                    locale="fr"
                                    dateFormat="dd/MM/yyyy"
                                    //showMonthYearPicker
                                    //showFullMonthYearPicker
                                    onChange={onChangeLastDate}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={lastDate} /> 
                    </div>
                </Col>

                <Col>
                    <div style={{ margin: "20px"}}>
                        
                        <label for="selectPartenaires">Filtre : </label>{"  "}
                        <select class="form-control" id="selectPartenaires" value={selectPartner} onChange={handlePartner}>
                            <option value="allFinance">---</option>
                            <option value="recap_recettes">Récapitulatif recettes</option>
                            <option value="recap_recette_caisse">Recettes de la Caisse</option>
                            <option value="recap_recette_caisse_total">Recettes de la Caisse Total</option>
                        </select>
                    </div>
                </Col>
                <Col>
                    <div style={{ margin: "20px"}}>
                        <label>Options : </label>
                        <Row>
                            <Col>
                                <JsfPdfDoc source="table-to-xls" title="SUIVI DES RECETTES"/>
                            </Col>
                            <Col>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="btn btn-primary download-table-xls-button"
                                    table="table-to-xls"
                                    filename="tablexls"
                                    sheet="tablexls"
                                    buttonText="Telecharger excel"/>
                            </Col>
                        </Row>
                    </div>
                    
                </Col>
            </Row>
    
             <ShowTableFinanceMois choix={selectPartner} filterRecettes={filterFinances}  listMontant={listMontant}/>
        </div>
    );
};

export default FinanceMois;