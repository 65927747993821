import React, { Fragment, useState } from 'react';
import RecetteContext from '../context/RecetteContext'
import { useContext } from 'react';
import RecetteUpdate from './RecetteUpdate';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'


const RecetteItems = ({recette, numLigne}) => {

    const recetteContext = useContext(RecetteContext)
    const { deleteRecette, setSelectedRecette } = recetteContext
    const [show, setShow] = useState(false)

    const { 
        id,
        dateComptable,quantite,ordre,
        formExpress,locationAutre,dfde,
        droits,penalite,timbres,
        total,greffe,ogapi,
        insertionJournal,totalFinal 
    } = recette;

    const handleDelete = () => {
        deleteRecette(id)
    }

    const handleUpdate = () => {
        console.log(recette)
        setSelectedRecette(recette)
        setShow(!show)
    }

    return (
        <Fragment>
            <tr>
                <th scope="row">{ numLigne }</th>
                <td><Moment format="DD/MM/YYYY">{dateComptable}</Moment></td>
                <td>{quantite}</td>
                <td>{ordre}</td>
                <td>{new Intl.NumberFormat("de-DE").format(formExpress)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(locationAutre)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(dfde)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(droits)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(penalite)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(timbres)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(total)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(greffe)}</td>

                <td>{new Intl.NumberFormat("de-DE").format(ogapi)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(insertionJournal)}</td>
                <td>{new Intl.NumberFormat("de-DE").format(totalFinal)}</td>
                <td>
                    <button className="btn btn-danger" onClick={handleDelete}><FontAwesomeIcon icon={faTrashAlt} /></button>
                    <button className="btn btn-primary" onClick={handleUpdate}><FontAwesomeIcon icon={faEdit} /></button>
                </td>
            </tr>
            <RecetteUpdate onShow={show} change={handleUpdate}/>
        </Fragment>
    );
};

export default RecetteItems;