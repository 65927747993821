import React, { useState, useEffect, useContext, useRef } from 'react';
import { recetteMoisOf } from '../utility/Utils';
import { Row, Col } from "react-bootstrap"
import ShowTable from './ShowTable';
import { v4 as uuidv4 } from 'uuid';
import RecetteContext from "../context/RecetteContext"
import RestantPartenaires from './RestantPartenaires';
import JsfPdfDoc from '../Etats/JsPdfDoc';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const PartnersMois = ({dateCompt,montantPartenairesMois}) => {

    const recetteContext = useContext(RecetteContext)
    const { addRestantPartenaire } = recetteContext
    const [versement, setVersement] = useState(0)
    const [versementJo, setVersementJo] = useState('')
    const [versementOgapi, setVersementOgapi] = useState('')
    const [versementGreffe, setVersementGreffe] = useState('')
    const [quote, setQuote] = useState('')
    const [restant, setRestant] = useState(0)
    const [restantPartenaire, setRestantPartenaire] = useState({
        id: "",
        dateComptable: "",
        versementJo: "",
        versementOgapi: "",
        versementGreffe: "",
        quoteR: "",
        totalVersement: "",
        restantR: ""
    })

    const versementJoRef = useRef(null)
    const versementOgapiRef = useRef(null)
    const versementGreffeRef = useRef(null)
    const quoteRef = useRef(null)
    const versementBtn = useRef(null)

    useEffect(() => {
        handleReste()
    }, [versement, quote, montantPartenairesMois])

    useEffect(() => {
        handleSommeVersement()
    }, [versementJo, versementOgapi, versementGreffe])

    const handleSubmit = (e) => {
        e.preventDefault();

        restantPartenaire.id = uuidv4();
        restantPartenaire.dateComptable = dateCompt;
        restantPartenaire.versementJo = versementJo;
        restantPartenaire.versementOgapi = versementOgapi;
        restantPartenaire.versementGreffe = versementGreffe;
        restantPartenaire.quoteR = quote;
        restantPartenaire.totalVersement = versement;
        restantPartenaire.restantR = restant;

        //Add Line
        if(restantPartenaire.totalVersement > 0 && restantPartenaire.restantR >= 0) {
            addRestantPartenaire(restantPartenaire)

            setRestantPartenaire({
                id: "",
                dateComptable: "",
                versementJo: "",
                versementOgapi: "",
                versementGreffe: "",
                quoteR: "",
                totalVersement: "",
                restantR: ""
            })

            setVersement(0);
            setVersementJo(0);
            setVersementOgapi(0);
            setVersementGreffe(0);
            setQuote(0);
        }
        
        handleVersementJoFocus()
    }

    const handleVersementJoFocus = () => {
        versementJoRef.current.focus();
    }

    const handleVersement = (e) => {
        setVersement(e.target.value)
    }

    const handleJo = (e) => {
        setVersementJo(e.target.value)
    }

    const handleOgapi = (e) => {
        setVersementOgapi(e.target.value)
    }

    const handleGreffe = (e) => {
        setVersementGreffe(e.target.value)
    }

    const handleQuote = (e) => {
        setQuote(e.target.value)
    }

    const handleReste = () => {
        let reste, versementF, quoteF = 0;
        
        if(versement === "" ) {
            versementF = 0;
        }else {
            versementF =  versement
        }
        if(quote === "") {
            quoteF = 0;
        }else {
            quoteF = quote
        }

        reste = recetteMoisOf(montantPartenairesMois) - (parseInt(versementF) + parseInt(quoteF))
        setRestant(reste)
    }

    const handleSommeVersement = () => {
        let somme, Jo, Ogapi, Greffe  = 0;
        
        if(versementOgapi === "" ) {
            Ogapi = 0;
        }else {
            Ogapi = parseInt(versementOgapi) 
        }
        if(versementJo === "") {
            Jo = 0;
        }else {
            Jo = versementJo
        }

        if(versementGreffe === "") {
            Greffe = 0;
        }else {
            Greffe = versementGreffe
        }

        somme = (parseInt(Ogapi) + parseInt(Jo) + parseInt(Greffe))
        setVersement(somme)
    }

    return (
        <div style={{ border:"solid", padding: "10px", margin: "10px" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ margin: "20px"}}>
                    <Row>
                        <Col>
                            <label>Versements effectués aux partenaires : </label>
                            <input type="number" className="form-control" disabled="true"
                                    name="versement" onChange={handleVersement} onKeyUp={handleVersement}
                                    value={versement} placeholder="0"/>
                            <label>Versements JO : </label>
                            <input type="number" className="form-control" 
                                    ref={versementJoRef} onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            versementOgapiRef.current.focus()
                                        }
                                    }}
                                    name="jo" onChange={handleJo} onKeyUp={handleJo}
                                    value={versementJo} placeholder="0"/>
                            <label>Versements OGAPI : </label>
                            <input type="number" className="form-control" 
                                    ref={versementOgapiRef} onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            versementGreffeRef.current.focus()
                                        }
                                    }}
                                    name="ogapi" onChange={handleOgapi} onKeyUp={handleOgapi}
                                    value={versementOgapi} placeholder="0"/>
                             <label>Versements GREFFE : </label>
                            <input type="number" className="form-control" 
                                    name="greffe" onChange={handleGreffe} onKeyUp={handleGreffe}
                                    ref={versementGreffeRef} onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            quoteRef.current.focus()
                                        }
                                    }}
                                    value={versementGreffe} placeholder="0"/>
                        </Col>
                        <Col>
                            <label>Quote-part Anpi-Gabon reçue : </label>
                            <input type="number" className="form-control"
                                    ref={quoteRef} onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            //e.preventDefault()
                                            //versementBtn.current.focus()
                                        }
                                    }}
                                    name="quote" onChange={handleQuote} onKeyUp={handleQuote}
                                    value={quote} placeholder="0"/>
                        </Col>
                        <Col>
                            <label>Total partenaires</label>
                            <input type="text" className="form-control" name="restant" placeholder={recetteMoisOf(montantPartenairesMois)} disabled/>
                            <label>Restant dû aux partenaires</label>
                            <input type="text"  className="form-control" value={restant} />
                        </Col>
                    </Row>
                </div>
                <Row style={{ marginBottom:"10px"}}>
                    <Col>
                        <button type="submit" ref={versementBtn} className="btn btn-primary">Enregistrer</button>
                    </Col>
                    <Col>
                        <JsfPdfDoc source="table-to-partners" title="SUIVI DES RECETTES"/>
                    </Col>
                    <Col>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-primary download-table-xls-button"
                            table="table-to-partners"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText="Telecharger excel"/>
                    </Col>
                </Row>
                
            </form>

             <RestantPartenaires dateCompt={dateCompt}/> 
        </div>
        
    );
};

export default PartnersMois;