import React, { useReducer} from 'react';
import RecetteContext from './RecetteContext'
import RecetteReducer from './RecetteReducer'
import axios from 'axios';
import moment from "moment"

const RecetteState = (props) => {

    //const url = 'http://169.254.2.250:5000'
    //const url = 'http://localhost:5000'
    const url = '';
    const initialState = {
        filterRestantPartenaires: [],
        filterRecettes: null,
        filterFinances: null,
        recettes: [],
        finances: [],
        restantPartenaires: [],
        selectedRecette: null,
        selectedFinance: null,
        montantRecetteJour: null,
        montantDroitsJour: null,
        montantRecetteMois: null,
        montantDroitsMois: null,
        montantPenaliteMois: null,
        montantPenaliteJour: null,
        montantPartenairesMois: null,
        montantExpressJour: null,
        montantExpressMois: null,
        montantLocationJour: null,
        montantLocationMois: null,
        montantDfdeJour: null,
        montantDfdeMois: null,
        montantGreffeJour: null,
        montantGreffeMois: null,
        montantOgapiJour: null,
        montantOgapiMois: null,
        montantInsertionJournalJour: null,
        montantInsertionJournalMois: null,
        montantDGIJour: null,
        montantDGIMois: null,
        montantTimbreJour: null,
        montantTimbreMois: null,
        montantFinanceAnpiJour: null,
        montantFinanceDGIJour: null,
        montantFinanceGreffeJour: null,
        montantFinanceOgapiJour: null,
        montantFinanceInsertionJournalJour: null,
        montantFraisJour: null,
        montantFinanceAnpiReelJour: null,
        montantReversementEtat: null,
        montantFinanceDGIMois: null,
        montantFinanceAnpiMois: null,
        montantFinanceGreffeMois: null,
        montantFinanceOgapiMois: null,
        montantFinanceInsertionJournalMois: null,
        montantFraisMois: null,
        montantFinanceAnpiReelMois: null,
        montantReversementEtatMois: null,
        montantFinanceGlobalAnpiMois: null,
        montantFinanceAnpiJourDansMois: null
    }

    const [state, dispatch] = useReducer(RecetteReducer, initialState)

    const allRecettes = async () => {
        const response = await axios.get(`${url}/recettes`)
         // .then(resp => resp.data) 
       // console.log(response.data.data)
            dispatch({
              type: "ALL_RECETTES",
              payload: response.data.data
          })  
    }

    const allFinances = async () => {
        const response = await axios.get(`${url}/finances`)
         // .then(resp => resp.data) 

          dispatch({
              type: "ALL_FINANCES",
              payload: response.data.data
          })
    }

    const allRestantPartenaires = async () => {
        const response = await axios.get(`${url}/restantPartenaires`)
         // .then(resp => resp.data) 

          dispatch({
              type: "ALL_RESTANT_PARTENAIRES",
              payload: response.data.data
          })
    }

    const allRecettesByDate = (date) => {
        console.log("recetteJour")
        dispatch({
            type: "ALL_RECETTES_DATE",
            payload: moment(date).format('L')
        })
    }

    const allFinancesByDate = (date) => {
        console.log("recetteFinanceJour")
        dispatch({
            type: "ALL_FINANCES_DATE",
            payload: moment(date).format('L')
        })
    }

    const allFinanceBetweenDate = (date, lastDate) => {
        console.log(`recetteFinanceBetweenDay:  ${moment(date).format('L')} et ${moment(lastDate).format('L')}`)

        dispatch({
            type: "ALL_FINANCES_BETWEEN_DATE",
            payload: {
                debut: moment(date).format('L'),
                fin: moment(lastDate).format('L')
            } 
           
        })
    }

    const allRecettesByMonth = (date) => {
        
        dispatch({
            type: "ALL_RECETTES_MOIS",
            payload: date
        })
    }

    const allFinancesByMonth = (date) => {
        
        dispatch({
            type: "ALL_FINANCES_MOIS",
            payload: date
        })
    }

    const allFinancesBetweenMonth = (dateDeb, dateFin) => {
        
        dispatch({
            type: "ALL_FINANCES_BETWEEN_MOIS",
            payload: {
                dateDeb: dateDeb,
                dateFin: dateFin
            }
        })
    }

    const allRestantPartenairesByMonth = (date) => {
        console.log(date)
        dispatch({
            type: "ALL_RESTANT_PARTENAIRES_MOIS",
            payload: date
        })
    }

    const setSelectedRecette = (recette) => {
        dispatch({
            type: "SELECTED_RECETTE",
            payload: recette
        })
    }

    const setSelectedFinance = (finance) => {
        dispatch({
            type: "SELECTED_FINANCE",
            payload: finance
        })
    }

    const deleteRecette = async (id) => {
       
        axios.delete(`${url}/recettes/${id}`);
       
        dispatch({
            type: "DELETE_RECETTE",
            payload: id
        })
    }

    const deleteFinance = async (id) => {
        axios.delete(`${url}/finances/${id}`);

        dispatch({
            type: "DELETE_FINANCE",
            payload: id
        })
    }
    
    const deleteRestantPartenaire = async (id) => {
       
        axios.delete(`${url}/restantPartenaires/${id}`);
       
        dispatch({
            type: "DELETE_RESTANT_PARTENAIRE",
            payload: id
        })
    }

    const updateRecette = async (recette) => {
        axios.put(`${url}/recettes/${recette.id}`, recette);
        dispatch({
            type: "UPDATE_RECETTE",
            payload: recette
        })
    }

    const updateFinance = async (finance) => {
        axios.put(`${url}/finances/${finance.id}`, finance);
        dispatch({
            type: "UPDATE_FINANCE",
            payload: finance
        })
    }

    const addRecette = async (recette) => {
        axios.post(`${url}/recettes`, recette);
        console.log(recette)
        dispatch({
            type: "ADD_RECETTE",
            payload: recette
        })
    }

    const addFinance = async (finance) => {
        axios.post(`${url}/finances`, finance);
        console.log(finance);
        dispatch({
            type: "ADD_FINANCE",
            payload: finance
        })
    }

    const addRestantPartenaire = async (restantPartenaire) => {
        axios.post(`${url}/restantPartenaires`, restantPartenaire);
        console.log(restantPartenaire)
        dispatch({
            type: "ADD_RESTANT_PARTENAIRE",
            payload: restantPartenaire
        })
    }

    const clearRecette = () => {
        dispatch({
            type: "CLEAR_RECETTE"
        })
    }

    const clearSeletedRecette = () => {
        dispatch({
            type: "CLEAR_SELECTED_RECETTE"
        })
    }

    const clearSeletedFinance = () => {
        dispatch({
            type: "CLEAR_SELECTED_FINANCE"
        })
    }

    const recetteJour = (date) => {
        console.log("recetteJour")
        dispatch({
            type: "RECETTE_JOUR",
            payload: moment(date).format('L')
        })
    }

    const financeAnpiJour = (date, lastDate, filter) => {
        console.log(`filter : ${filter}`)
        dispatch({
            type: "FINANCE_ANPI_JOUR",
            payload: {
                dateDeb: moment(date).format('L'),
                dateFin: moment(lastDate).format('L'),
                filter: filter
            }
        })
    }

    const financeDGIJour = (date, lastDate, filter) => {
        dispatch({
            type: "FINANCE_DGI_JOUR",
            payload: {
                dateDeb: moment(date).format('L'),
                dateFin: moment(lastDate).format('L'),
                filter: filter
            }
        })
    }

    const financeGreffeJour = (date, lastDate, filter) => {
        dispatch({
            type: "FINANCE_GREFFE_JOUR",
            payload: {
                dateDeb: moment(date).format('L'),
                dateFin: moment(lastDate).format('L'),
                filter: filter
            }
        })
    }

    const financeOgapiJour = (date, lastDate, filter) => {
        dispatch({
            type: "FINANCE_OGAPI_JOUR",
            payload: {
                dateDeb: moment(date).format('L'),
                dateFin: moment(lastDate).format('L'),
                filter: filter
            }
        })
    }

    const financeInsertionJournalJour = (date, lastDate, filter) => {
        dispatch({
            type: "FINANCE_JO_JOUR",
            payload: {
                dateDeb: moment(date).format('L'),
                dateFin: moment(lastDate).format('L'),
                filter: filter
            }
        })
    }

    const financeFraisJour = (date, lastDate, filter) => {
        dispatch({
            type: "FINANCE_FRAIS_JOUR",
            payload: {
                dateDeb: moment(date).format('L'),
                dateFin: moment(lastDate).format('L'),
                filter: filter
            }
        })
    } 
    
    const financeAnpiReelJour = (date, lastDate, filter) => {
        dispatch({
            type: "FINANCE_ANPI_REEL_JOUR",
            payload: {
                dateDeb: moment(date).format('L'),
                dateFin: moment(lastDate).format('L'),
                filter: filter
            }
        })
    } 

    const reversementEtatJour = (date, lastDate, filter) => {
        dispatch({
            type: "FINANCE_REVERSEMENT_ETAT_JOUR",
            payload: {
                dateDeb: moment(date).format('L'),
                dateFin: moment(lastDate).format('L'),
                filter: filter
            }
        })
    } 

    const recetteDroitJour = (date) => {
        
        dispatch({
            type: "RECETTE_DROITS_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteDroitMois = (date) => {
        
        dispatch({
            type: "RECETTE_DROITS_MOIS",
            payload: date
        })
    }

    const recettePenaliteJour = (date) => {
        
        dispatch({
            type: "RECETTE_PENALITES_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteExpressJour = (date) => {
        
        dispatch({
            type: "RECETTE_EXPRESS_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteExpressMois = (date) => {
        
        dispatch({
            type: "RECETTE_EXPRESS_MOIS",
            payload: date
        })
    }

    const recetteDGIJour = (date) => {
        
        dispatch({
            type: "RECETTE_DGI_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteDGIMois = (date) => {
        
        dispatch({
            type: "RECETTE_DGI_MOIS",
            payload: date
        })
    }

    const financeDGIMois = (dateDeb, dateFin) => {
        
        dispatch({
            type: "FINANCE_DGI_MOIS",
            payload: {
                dateDeb: dateDeb,
                dateFin: dateFin
            }
        })
    }

    const recetteLocationJour = (date) => {
        
        dispatch({
            type: "RECETTE_LOCATION_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteLocationMois = (date) => {
        
        dispatch({
            type: "RECETTE_LOCATION_MOIS",
            payload: date
        })
    }

    const recetteDfdeJour = (date) => {
        
        dispatch({
            type: "RECETTE_DFDE_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteDfdeMois = (date) => {
        
        dispatch({
            type: "RECETTE_DFDE_MOIS",
            payload: date
        })
    }

    const recetteGreffeJour = (date) => {
        
        dispatch({
            type: "RECETTE_GREFFE_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteGreffeMois = (date) => {
        
        dispatch({
            type: "RECETTE_GREFFE_MOIS",
            payload: date
        })
    }

    const financeAnpiMois = (dateDeb, dateFin) => {
        
        dispatch({
            type: "FINANCE_ANPI_MOIS",
            payload: {
                dateDeb: dateDeb,
                dateFin: dateFin
            }
        })
    }

    const financeAnpiGlobalMois = (date) => {
       
        dispatch({
            type: "FINANCE_ANPI_GLOBAL_MOIS",
            payload: date
        })
    }

    const financeAnpiJourDansMois = (date) => {
        console.log(date)
        dispatch({
            type: "FINANCE_ANPI_JOUR_DANS_MOIS",
            payload: date
        })
    }

    const financeGreffeMois = (dateDeb, dateFin) => {
        
        dispatch({
            type: "FINANCE_GREFFE_MOIS",
            payload: {
                dateDeb: dateDeb,
                dateFin: dateFin
            }
        })
    }

    const financeOgapiMois = (dateDeb, dateFin) => {
        
        dispatch({
            type: "FINANCE_OGAPI_MOIS",
            payload:  {
                dateDeb: dateDeb,
                dateFin: dateFin
            }
        })
    }

    const financeInsertionJournalMois = (dateDeb, dateFin) => {
        
        dispatch({
            type: "FINANCE_JO_MOIS",
            payload:  {
                dateDeb: dateDeb,
                dateFin: dateFin
            }
        })
    }

    const financeFraisMois = (dateDeb, dateFin) => {
        
        dispatch({
            type: "FINANCE_FRAIS_MOIS",
            payload: {
                dateDeb: dateDeb,
                dateFin: dateFin
            }
        })
    }

    const financeAnpiReelMois = (dateDeb, dateFin) => {
        
        dispatch({
            type: "FINANCE_ANPI_REEL_MOIS",
            payload: {
                dateDeb: dateDeb,
                dateFin: dateFin
            }
        })
    }

    const reversementEtatMois = (dateDeb, dateFin) => {
        
        dispatch({
            type: "FINANCE_REVERSEMENT_ETAT_MOIS",
            payload: {
                dateDeb: dateDeb,
                dateFin: dateFin
            }
        })
    }

    const recetteTimbreJour = (date) => {
        
        dispatch({
            type: "RECETTE_TIMBRE_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteTimbreMois = (date) => {
        
        dispatch({
            type: "RECETTE_TIMBRE_MOIS",
            payload: date
        })
    }
    
    const recetteOgapiJour = (date) => {
        
        dispatch({
            type: "RECETTE_OGAPI_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteOgapiMois = (date) => {
        
        dispatch({
            type: "RECETTE_OGAPI_MOIS",
            payload: date
        })
    }

    const recetteInsertionJournalJour = (date) => {
        
        dispatch({
            type: "RECETTE_JOURNAL_JOUR",
            payload: moment(date).format('L')
        })
    }

    const recetteInsertionJournalMois = (date) => {
        
        dispatch({
            type: "RECETTE_JOURNAL_MOIS",
            payload: date
        })
    }

    const recettePenaliteMois = (date) => {
        
        dispatch({
            type: "RECETTE_PENALITES_MOIS",
            payload: date
        })
    }

    const recetteMois = (date) => {

        dispatch({
            type: "RECETTE_MOIS",
            payload: date
        })
    }

    const recettePartenaireMois = (date) => {
        dispatch({
            type: "RECETTE_PARTENAIRES_MOIS",
            payload: date
        })
    }

    return (
        <RecetteContext.Provider
            value={{
                restantPartenaires: state.restantPartenaires,
                filterRestantPartenaires: state.filterRestantPartenaires,
                recettes: state.recettes,
                finances: state.finances,
                filterFinances: state.filterFinances,
                filterRecettes: state.filterRecettes,
                selectedRecette: state.selectedRecette,
                selectedFinance: state.selectedFinance,
                montantRecetteJour: state.montantRecetteJour,
                montantDroitsJour: state.montantDroitsJour,
                montantRecetteMois: state.montantRecetteMois,
                montantDroitsMois: state.montantDroitsMois,
                montantPenaliteMois: state.montantPenaliteMois,
                montantPenaliteJour: state.montantPenaliteJour,
                montantPartenairesMois: state.montantPartenairesMois,
                montantExpressJour: state.montantExpressJour,
                montantExpressMois: state.montantExpressMois,
                montantLocationJour: state.montantLocationJour,
                montantLocationMois: state.montantLocationMois,
                montantDfdeJour: state.montantDfdeJour,
                montantDfdeMois: state.montantDfdeMois,
                montantGreffeJour: state.montantGreffeJour,
                montantGreffeMois: state.montantGreffeMois,
                montantOgapiJour: state.montantOgapiJour,
                montantOgapiMois: state.montantOgapiMois,
                montantInsertionJournalJour: state.montantInsertionJournalJour,
                montantInsertionJournalMois: state.montantInsertionJournalMois,
                montantDGIJour: state.montantDGIJour,
                montantDGIMois: state.montantDGIMois,
                montantTimbreJour: state.montantTimbreJour,
                montantTimbreMois: state.montantTimbreMois,
                montantFinanceAnpiJour: state.montantFinanceAnpiJour,
                montantFinanceDGIJour: state.montantFinanceDGIJour,
                montantFinanceGreffeJour: state.montantFinanceGreffeJour,
                montantFinanceOgapiJour: state.montantFinanceOgapiJour,
                montantFinanceInsertionJournalJour: state.montantFinanceInsertionJournalJour,
                montantFraisJour: state.montantFraisJour,
                montantFinanceAnpiReelJour: state.montantFinanceAnpiReelJour,
                montantReversementEtat: state.montantReversementEtat,
                montantFinanceDGIMois: state.montantFinanceDGIMois,
                montantFinanceAnpiMois: state.montantFinanceAnpiMois,
                montantFinanceGreffeMois: state.montantFinanceGreffeMois,
                montantFinanceOgapiMois: state.montantFinanceOgapiMois,
                montantFinanceInsertionJournalMois: state.montantFinanceInsertionJournalMois,
                montantFraisMois: state.montantFraisMois,
                montantFinanceAnpiReelMois: state.montantFinanceAnpiReelMois,
                montantReversementEtatMois: state.montantReversementEtatMois,
                montantFinanceGlobalAnpiMois: state.montantFinanceGlobalAnpiMois,
                montantFinanceAnpiJourDansMois: state.montantFinanceAnpiJourDansMois,
                allFinances,
                allRecettes,
                addRecette,
                addFinance,
                addRestantPartenaire,
                updateRecette,
                updateFinance,
                setSelectedFinance,
                setSelectedRecette,
                clearSeletedRecette,
                clearSeletedFinance,
                clearRecette, 
                recetteJour,
                recetteMois,
                recetteDroitJour,
                recetteDroitMois,
                recettePenaliteJour,
                recettePenaliteMois,
                recettePartenaireMois,
                recetteExpressJour,
                recetteExpressMois,
                recetteLocationJour,
                recetteLocationMois,
                recetteDfdeJour,
                recetteDfdeMois,
                recetteGreffeJour,
                recetteGreffeMois,
                recetteOgapiJour,
                recetteOgapiMois,
                recetteInsertionJournalJour,
                recetteInsertionJournalMois,
                recetteDGIMois,
                recetteDGIJour,
                recetteTimbreJour,
                recetteTimbreMois,
                allRecettesByDate,
                allFinancesByDate,
                allRecettesByMonth,
                allFinancesByMonth,
                allRestantPartenairesByMonth,
                allRestantPartenaires,
                deleteRecette,
                deleteRestantPartenaire,
                deleteFinance,
                financeAnpiJour,
                financeDGIJour,
                financeDGIMois,
                financeGreffeJour,
                financeOgapiJour,
                financeInsertionJournalJour,
                financeFraisJour,
                financeAnpiReelJour,
                reversementEtatJour,
                financeAnpiMois,
                financeAnpiGlobalMois,
                financeAnpiJourDansMois,
                financeGreffeMois,
                financeOgapiMois,
                financeInsertionJournalMois,
                financeFraisMois,
                financeAnpiReelMois,
                reversementEtatMois,
                allFinanceBetweenDate,
                allFinancesBetweenMonth
            }}>
            {props.children}
        </RecetteContext.Provider>
    );
};

export default RecetteState;