import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import RecetteState from './context/RecetteState';
import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';
import HomePage from './pages/Home'
import Header from './pages/Header'
import RecettesPage from './pages/RecettesPage'
import RecetteMois from './recettes/RecetteMois'
import HomeFinance from './pages/HomeFinance'
import FinanceJour from './tresor/FinanceJour'
import FinanceMois from './tresor/FinanceMois'
import Landing from './pages/Landing';

import Register from './pages/Register';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Navbar from './pages/Navbar';
import PrivateRoute from './routing/PrivateRoute';
import setAuthToken from './utils/setAuthToken'
import Alerts from './pages/Alerts'

if(localStorage.usertoken) {
  setAuthToken(localStorage.usertoken);
}

const App = () => (
    <AuthState>
      <RecetteState>
        <AlertState>
          <Router>
            {/* <Header /> */}
            <Navbar />
            <div className="container">
              <Alerts />
              <Switch>
                <Route exact path="/" component={Landing}/>
                <PrivateRoute exact path="/saisieRecette" component={HomePage}/>
                <PrivateRoute exact path="/recettes" component={RecettesPage} />
                <PrivateRoute exact path="/recettesMois" component={RecetteMois} />
                <PrivateRoute exact path="/HomeFinance" component={HomeFinance} />
                <PrivateRoute exact path="/FinanceJour" component={FinanceJour} />
                <PrivateRoute exact path="/FinanceMois" component={FinanceMois} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/profile" component={Profile} />
              </Switch>
            </div>
          </Router>
        </AlertState>
      </RecetteState>
    </AuthState>
  );


export default App;
