import React, { useEffect, useState, Fragment, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import logo from '../logo-anpi.png'
import jwt_decode from 'jwt-decode'
import AuthContext from '../context/auth/AuthContext'

const Landing = (props) => {
  
  const authContext = useContext(AuthContext)
  const { isAuthenticated, loadUser, logout } = authContext;
  const [departement, setDepartement] = useState('')


  useEffect(() => {
    console.log("navbar")
      
    try {
      const token = localStorage.usertoken
  
      const decoded = jwt_decode(token)
      
    
      setDepartement(decoded.departement);
      
    } catch (error) {
      console.log(error)
    }
  }, [isAuthenticated])


  const logOut = (e) => {
    e.preventDefault()
    logout()
    props.history.push(`/`)
  }

  const loginRegLink = () => (
    <ul className="navbar-nav  mr-auto">
      <li className="nav-item">
        <Link to="/login" className="nav-link">
          Se connecter
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/register" className="nav-link">
          Creer un compte
        </Link>
      </li>
    </ul>
  )

  const userLink = ()  => {
    return <Fragment>
      <ul className="navbar-nav  mr-auto">
        
        { departement === "Tresor" ?  
            <Fragment>
              <Link className="nav-link" to="/saisieRecette">Saisies</Link>
              <Link className="nav-link" to="/recettes" >Recettes par jour</Link>
              <Link className="nav-link" to="/recettesMois">Recettes mois</Link>
            </Fragment>
         : 
         <Fragment>
              <Link className="nav-link" to="/HomeFinance">Saisie finance</Link>
              <Link className="nav-link" to="/FinanceJour">Finance Jour</Link>
              <Link className="nav-link" to="/FinanceMois">Finance Mois</Link>
         </Fragment>
        
        }
        
      </ul>
      
      <ul className="navbar-nav">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Paramètre
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <Link to="/profile" className="dropdown-item">
              Profile
            </Link>
            <div class="dropdown-divider"></div>
            <a href="" onClick={logOut} class="dropdown-item">
              Se deconnecter
            </a>
          </div>
        </li>

       {/*  <li className="nav-item">
            <Link to="/profile" className="dropdown-item">
              Profile
            </Link>
          </li> */}
        {/* <li className="nav-item ">
            <a href="" onClick={this.logOut.bind(this)} className="nav-link">
              Se deconnecter
            </a>
          </li> */}
      </ul>
    </Fragment>
    
        }

    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light rounded">
         <Link className="navbar-brand" to="/" > 
                <img
                src={logo}
                height="50"
                className="d-inline-block align-top"
                alt="Logo Anpi suivie de recette"/> 
            </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExample10"
          aria-controls="navbarsExample10"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div
          className="collapse navbar-collapse "
          id="navbarsExample10"
        >
          <ul className="navbar-nav ">
           
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Accueil
              </Link>
            </li>
          </ul>
          {localStorage.usertoken ? userLink() : loginRegLink()}
        </div>
      </nav>
    )
  
}

export default withRouter(Landing)