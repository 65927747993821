import React, { useEffect, useState, useContext } from 'react'
import AuthContext from '../context/auth/AuthContext'
import AlertContext from '../context/alert/AlertContext'

const Register = (props) => {

    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const { isRegisted, register, changeStateRegister } = authContext
    const { setAlert } = alertContext
    
    const [newUser, setNewUser] = useState({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      departement: 'compta'
    }) 

  const [errors, setErrors] = useState({})
  const { first_name, last_name, email, password, departement} = newUser

  const onChange = (e) => {
    setNewUser({...newUser, [e.target.name]: e.target.value })
  }
  

  useEffect(() => {
    if(isRegisted) {
         props.history.push('/login')
         changeStateRegister()
    }
  }, [isRegisted, props.history])


  const onSubmit = (e) => {
    e.preventDefault()
    if(first_name === '' || last_name === '' || email === '' || password === "") {
      setAlert("Completer les champs libres", "danger")
    } else {
      setAlert("Enregistrement effectue", "success")
      register(newUser)
    }
    
  }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-5 mx-auto">
            <form noValidate onSubmit={onSubmit}>
              <h1 className="h3 mb-3 font-weight-normal">Création de compte</h1>
              <div className="form-group">
                <label htmlFor="name">Nom</label>
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder="Entrer votre nom"
                  value={first_name} required
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Prénom</label>
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  placeholder="Entrer votre prénom"
                  value={last_name}  required
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Adresse mail</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Entrer adresse email"
                  value={email}  required
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Mot de passe</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Entrer votre mot de passe"
                  value={password}  required
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="departement">Département</label>
                <select class="form-control" id="departement"   name="departement" required value={departement} onChange={onChange}>
                    <option value="compta">Compta</option>
                    <option value="Tresor">Trésor</option>
                </select>
              </div>
              <button
                type="submit"
                className="btn btn-lg btn-primary btn-block"
              >
                Creer le compte
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  
}

export default Register