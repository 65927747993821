import React, { useContext, useEffect } from 'react';
import RecetteItems from './RecetteItems'
import RecetteContext from '../context/RecetteContext';
import { Row, Col } from "react-bootstrap"
import JsfPdfDoc from '../Etats/JsPdfDoc';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../table.scss'
const Recettes = () => {

  const recetteContext = useContext(RecetteContext);
  const { allRecettes, recettes } = recetteContext;

   useEffect(() => {
     allRecettes();
     console.log(recettes)
   }, []) 

    return (
        <div class="table-responsive-lg" style={{ marginTop: "10px"}}>
            <Row style={{ marginBottom:"10px"}}>  
                <Col>
                </Col>
                <Col>
                    <JsfPdfDoc source="vu-ensemble" title="SUIVI DES RECETTES"/>
                </Col>
                <Col>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-primary download-table-xls-button"
                        table="vu-ensemble"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Telecharger excel"/>
                </Col>
            </Row>
            <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table id="vu-ensemble" className="table table-bordered table-hover table-sm table-light">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Date Comptable</th>
                      <th scope="col">NºQA</th>
                      <th scope="col">NºOR</th>
                      <th scope="col">Formule Express</th>
                      <th scope="col">Location et autres</th>
                      <th scope="col">Autre DFDE</th>
                      <th scope="col">Droit (code 38)</th>
                      <th scope="col">Penalite (code 81)</th>
                      <th scope="col">Timbre (code 75)</th>
                      <th scope="col">Total</th>
                      <th scope="col">Frais de Greffe</th>
                      <th scope="col">Frais Ogapi</th>
                      <th scope="col">Insertion Journal Officiel</th>
                      <th scope="col">Total</th>
                      {/* <th scope="col">Operations</th> */}
                    </tr>
                  </thead>
                  <tbody>
                      { recettes.map((recette, i) => (
                        <RecetteItems recette={recette} numLigne={i+1} key={i} />
                      ))
                      }
                  </tbody>
              </table>
            </div>
        </div>
    );
};

export default Recettes;