import React from 'react';
import SaisieTresor from '../tresor/SaisieTresor';
import ListSaisie from '../tresor/ListSaisie';

const HomeFinance = () => (
    <div style={{ marginTop: "50px"}}>
        {/* <div style={{ margin: "20px"}}>
            <h1 className="display-4">
                    Ajout et liste Suivie des recettes
            </h1>
        </div> */}
        <SaisieTresor />
        <ListSaisie />
    </div>
);

export default HomeFinance;