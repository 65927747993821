import React, { useState, useContext, useEffect } from 'react';
import RecetteContext from "../context/RecetteContext"
import DatePicker, { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr'
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ShowTable from './ShowTable';
import JsfPdfDoc from '../Etats/JsPdfDoc';


const RecetteJour = () => {
    registerLocale("fr", fr);
    const recetteContext = useContext(RecetteContext)
    const { allRecettes, montantRecetteJour, recetteDroitJour,montantDGIJour,
        recetteDroitMois, recettePenaliteJour,recettePenaliteMois,recetteDGIJour, filterRecettes,
        recettePartenaireMois ,recetteMois, recetteJour, recetteExpressJour, recetteTimbreJour,
        recetteExpressMois, recetteLocationJour, recetteLocationMois,
        recetteDfdeJour, recetteDfdeMois, recetteGreffeJour, recetteGreffeMois,
        recetteOgapiMois, recetteOgapiJour, recetteInsertionJournalJour, recetteInsertionJournalMois,
        montantDroitsJour, montantPenaliteJour, montantExpressJour, allRecettesByDate,
        montantLocationJour,  montantDfdeJour,  montantGreffeJour,  montantOgapiJour, montantTimbreJour,
        montantInsertionJournalJour} = recetteContext
    const [startDate, setStartDate] = useState(new Date());
    const [selectPartner, setSelectPartner] = useState();
    const [listMontant, setListMontant] = useState(null)
   let total;
   useEffect(() => {
    allRecettesByDate(startDate)
    recetteJour(startDate)
    recetteDroitJour(startDate)
    recettePenaliteJour(startDate)
    recetteExpressJour(startDate)
    recetteLocationJour(startDate)
    recetteDfdeJour(startDate)
    recetteGreffeJour(startDate)
    recetteOgapiJour(startDate)
    recetteInsertionJournalJour(startDate)
    recetteTimbreJour(startDate)
    recetteDGIJour(startDate)
    
   }, [startDate])

   useEffect(() => {
    allRecettes()
   }, [])

   useEffect(() => {
    handleMontantPartner()
   }, [filterRecettes, selectPartner])

   const onChangeDate = (date) => {
    setStartDate(date)
   }

   const handlePartner = (e) => {
    setSelectPartner(e.target.value)
   }

   const handleMontantPartner = () => {
       
        switch (selectPartner) {
            case "anpi":
                setListMontant([montantExpressJour, montantLocationJour, montantDfdeJour, montantRecetteJour])
                break;
            case "journal":
                setListMontant([montantInsertionJournalJour])
                break;
            case "ogapi":
                setListMontant([montantOgapiJour])
                break;
            case "greffe":
                setListMontant([montantGreffeJour])
                break;
            case "dgi":
                setListMontant([montantDroitsJour, montantPenaliteJour, montantTimbreJour, montantDGIJour])
                break;
            case "droits":
                setListMontant([montantDroitsJour])
                break;
            case "penalites":
                setListMontant([montantPenaliteJour])
                break;
            case "timbres":
                setListMontant([montantTimbreJour])
                break;
            default:
                setListMontant([montantExpressJour, montantLocationJour, montantDfdeJour,
                    montantDroitsJour, montantPenaliteJour, montantTimbreJour, montantDGIJour,
                    montantGreffeJour, montantOgapiJour, montantInsertionJournalJour, montantRecetteJour])
                break;
        }
    }

    return (
        <div>
            <div style={{ margin: "20px"}}>
               <h1 className="display-4">
                    Suivie de recettes par jour
               </h1>
            </div>

            <Row>
                <Col>
                    <div style={{ margin: "20px"}}>
                    
                        <label>Selectionner la date : </label>{"  "}
                        <br />
                        <DatePicker className="form-control" 
                                    selected={startDate}
                                    locale="fr"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={onChangeDate}/> 
                    </div>
                </Col>

                <Col>
                    <div style={{ margin: "20px"}}>
                        
                        <label for="selectPartenaires">Trier par partenaire : </label>{"  "}
                        <select class="form-control" id="selectPartenaires" value={selectPartner} onChange={handlePartner}>
                            <option value="tout">Tout</option>
                            <option value="anpi">Anpi</option>
                            <option value="dgi">DGI</option>
                            <option value="droits">--Droits</option>
                            <option value="penalites">--Penalités</option>
                            <option value="timbres">--Timbres</option>
                            <option value="greffe">Greffe</option>
                            <option value="ogapi">Ogapi</option>
                            <option value="journal">Insertion Journal Officiel</option>
                        </select>
                    </div>
                </Col>
                <Col>
                    <div style={{ margin: "20px"}}>
                        <label>Options : </label>
                        <Row>
                            <Col>
                                <JsfPdfDoc source="table-to-xls" title="SUIVI DES RECETTES"/>
                            </Col>
                            <Col>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="btn btn-primary download-table-xls-button"
                                    table="table-to-xls"
                                    filename="tablexls"
                                    sheet="tablexls"
                                    buttonText="Telecharger excel"/>
                            </Col>
                        </Row>
                    </div>
                    
                </Col>
            </Row>
    
             <ShowTable choix={selectPartner} filterRecettes={filterRecettes} listMontant={listMontant}/>
        </div>
    );
};

export default RecetteJour;