import React, { Fragment } from 'react';
import RecetteForm from '../recettes/RecetteForm';
import Recettes from '../recettes/Recettes';

const HomePage = () => {
    return (
        <div style={{ marginTop: "50px"}}>
            <div style={{ margin: "20px"}}>
               <h1 className="display-4">
                    Ajout et liste Suivie des recettes
               </h1>
            </div>
          
          <RecetteForm />
          <Recettes />
        </div>
    );
};

export default HomePage;