import moment from 'moment';
import { parse } from '@fortawesome/fontawesome-svg-core';

export default function(state, action) {
    switch (action.type) {
        case "ALL_RECETTES":
            return {
                ...state,
                recettes: action.payload
            }
        case "ALL_FINANCES":
            return {
                ...state,
                finances: action.payload
            }
        case "ALL_RESTANT_PARTENAIRES":
            return {
                ...state,
                restantPartenaires: action.payload
            }
        case "ALL_RECETTES_DATE":
            return {
                ...state,
                filterRecettes: state.recettes.filter(recette => {
                    console.log(recette.dateComptable)
                    if(moment(recette.dateComptable).format('L') === action.payload) {
                        return recette
                    }
                }  )
            }

            case "ALL_FINANCES_BETWEEN_DATE":
                return {
                    ...state,
                    filterFinances: state.finances.filter(finance => {
                    
                        if((moment(finance.dateComptable).format('L') >= action.payload.debut) &&
                        (moment(finance.dateComptable).format('L') <= action.payload.fin) ) {
                            return finance
                        }
                    }  )
                }
        
        case "ALL_FINANCES_DATE":
            return {
                ...state,
                filterFinances: state.finances.filter(finance => {
                    
                    if(moment(finance.dateComptable).format('L') === action.payload) {
                        return finance
                    }
                }  )
            }
        case "ALL_RESTANT_PARTENAIRES_MOIS":
            return {
                ...state,
                filterRestantPartenaires: state.restantPartenaires.filter(restantPartenaire => (
                    ((new Date(restantPartenaire.dateComptable).getMonth()  === action.payload.getMonth()) && 
                        (new Date(restantPartenaire.dateComptable).getFullYear()  === action.payload.getFullYear())
                      ) ? 
                      restantPartenaire
                        : 0 
                ))
            }
            case "ALL_RECETTES_MOIS":
                return {
                    ...state,
                    filterRecettes: state.recettes.filter(recette => (
                        ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                            (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear())
                          ) ? 
                            recette
                            : 0 
                    ))
                }
        case "ALL_FINANCES_MOIS":
            return {
                ...state,
                filterFinances: state.finances.filter(finance => (
                    ((new Date(finance.dateComptable).getMonth()  === action.payload.getMonth()) && 
                        (new Date(finance.dateComptable).getFullYear()  === action.payload.getFullYear())
                        ) ?
                         finance   
                        : 
                        0
                 ) )
                
            }
        case "ALL_FINANCES_BETWEEN_MOIS":
            return {
                ...state,
                filterFinances: state.finances.filter(finance => {
                    if((new Date(finance.dateComptable).getMonth() >= action.payload.dateDeb.getMonth()) 
                    && (new Date(finance.dateComptable).getFullYear()  >= action.payload.dateDeb.getFullYear())
                    && (new Date(finance.dateComptable).getMonth() <= action.payload.dateFin.getMonth())
                    && (new Date(finance.dateComptable).getFullYear()  <= action.payload.dateFin.getFullYear())
                        ) {
                            return finance
                        }else {
                            return 0;
                        }
                 } )
                
            }
        case "DELETE_RECETTE":
            return {
                ...state,
                recettes: state.recettes.filter(recette => (recette.id !== action.payload))
            }
        case "DELETE_FINANCE":
            return {
                ...state,
                finances: state.finances.filter(finance => (finance.id !== action.payload))
            }
        case "DELETE_RESTANT_PARTENAIRE":
            return {
                ...state,
                filterRestantPartenaires: state.filterRestantPartenaires.filter(restant => (restant.id !== action.payload)),
                restantPartenaires: state.restantPartenaires.filter(restant => (restant.id !== action.payload))
            }
        case "ADD_RECETTE":
            return {
                ...state,
                recettes: [...state.recettes, action.payload]
            }
        case "ADD_FINANCE":
            return {
                ...state,
                finances: [...state.finances, action.payload]
            }
        case "ADD_RESTANT_PARTENAIRE":
            return {
                ...state,
                restantPartenaires: [...state.restantPartenaires, action.payload]
            }
        case "UPDATE_RECETTE":
            return {
                ...state,
                recettes: state.recettes.map(recette => (recette.id === action.payload.id) ? action.payload : recette)
            }
        case "UPDATE_FINANCE":
            return {
                ...state,
                finances: state.finances.map(finance => (finance.id === action.payload.id) ? action.payload : finance)
            }
        case "SELECTED_RECETTE":
            return {
                ...state,
                selectedRecette: action.payload
            }
        case "SELECTED_FINANCE":
            return {
                ...state,
                selectedFinance: action.payload
            }
        case "CLEAR_SELECTED_RECETTE":
            return {
                ...state,
                selectedRecette: null
            }
        case "CLEAR_SELECTED_FINANCE":
            return {
                ...state,
                selectedFinance: null
            }
        case "CLEAR_RECETTE":
            return {
                ...state,
                selectedRecette: null
            }
        case "RECETTE_JOUR":
            return {
                ...state,
                montantRecetteJour: state.recettes
                        .map(recette => (
                             (moment(recette.dateComptable).format('L')  === action.payload) ? 
                                recette.totalFinal
                             : 0
                        ))
            }
        
            
        case "FINANCE_ANPI_JOUR":
            return {
                ...state,
                montantFinanceAnpiJour: state.finances
                        .map(finance => {
                            if(action.payload.dateFin !== "") {
                                if((moment(finance.dateComptable).format('L')  >= action.payload.dateDeb) 
                                && (moment(finance.dateComptable).format('L')  <= action.payload.dateFin)
                                && finance.anpi !== "" ) {
                                    if(finance.nature === action.payload.filter) {
                                        return parseInt(finance.anpi) 
                                    } else if(finance.igu === action.payload.filter) {
                                        return parseInt(finance.anpi)
                                    } else if(action.payload.filter === "recap_recettes") {
                                        return parseInt(finance.anpi)
                                    }else if(action.payload.filter === "allFinance") {
                                        return parseInt(finance.anpi) 
                                    } else if(action.payload.filter === "recap_recette_caisse_total") {
                                        return parseInt(finance.anpi) 
                                    } else {
                                        return 0
                                    }
                                } else {
                                    return 0
                                  }
                                   
                            }else {
                              return  ((moment(finance.dateComptable).format('L')  === action.payload.dateDeb) && finance.anpi !== "") ? 
                                parseInt(finance.anpi) 
                                    : 0
                            }
                               
            })
            }
        case "FINANCE_ANPI_JOUR_DANS_MOIS":
            return {
                ...state,
                montantFinanceAnpiJourDansMois: state.finances
                        .map(finance => (
                            ((moment(finance.dateComptable).format('L')  === action.payload) && finance.anpi !== "") ? 
                                parseInt(finance.anpi)
                            : 0
                        ))
            }

        case "FINANCE_ANPI_REEL_JOUR":
            return {
                ...state,
                montantFinanceAnpiReelJour: state.finances
                        .map(finance => {
                            if(action.payload.dateFin !== "") {
                               if((moment(finance.dateComptable).format('L')  >= action.payload.dateDeb) 
                                && (moment(finance.dateComptable).format('L')  <= action.payload.dateFin)
                                && finance.anpi !== "" 
                                && finance.greffe !== ""
                                && finance.insertionJournal !== "" ) {
                                    if(finance.nature === action.payload.filter) {
                                        return parseInt(finance.anpi) * 0.75 
                                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500
                                        + parseInt(finance.insertionJournal) * (1/3)
                                    } else if(action.payload.filter === "recap_recettes") {
                                        return parseInt(finance.anpi) * 0.75 
                                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500
                                        + parseInt(finance.insertionJournal) * (1/3)
                                    }else if(action.payload.filter === "allFinance") {
                                        return parseInt(finance.anpi) * 0.75 
                                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500
                                        + parseInt(finance.insertionJournal) * (1/3)
                                    }else if(action.payload.filter === "recap_recette_caisse") {
                                        return parseInt(finance.anpi) * 0.75 
                                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500
                                        + parseInt(finance.insertionJournal) * (1/3)
                                    }else {
                                        return 0
                                    }
                                } else {
                                    return 0;
                                }
                            } else {
                               return ((moment(finance.dateComptable).format('L')  === action.payload.dateDeb) 
                                && finance.anpi !== "" 
                                && finance.greffe !== "")
                                && finance.insertionJournal !== "" ? 
                            parseInt(finance.anpi) * 0.75 
                            + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500
                            + parseInt(finance.insertionJournal) * (1/3)
                                : 0
                            }
                           
                        })
            }
        
        case "FINANCE_REVERSEMENT_ETAT_JOUR":
            return {
                ...state,
                montantReversementEtat: state.finances
                        .map(finance => {
                            if(action.payload.dateFin !== "") { 
                               if((moment(finance.dateComptable).format('L')  >= action.payload.dateDeb) 
                                && (moment(finance.dateComptable).format('L')  <= action.payload.dateFin) 
                                && finance.anpi !== "" 
                                && finance.greffe !== ""
                                && finance.insertionJournal !== ""
                                && finance.frais !== "") {

                                    if(finance.nature === action.payload.filter) {
                                        return parseInt(finance.frais) - (parseInt(finance.anpi) * 0.75 
                                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500 + parseInt(finance.insertionJournal) * (1/3))
                                    } else if(action.payload.filter === "recap_recettes") {
                                        return parseInt(finance.frais) - (parseInt(finance.anpi) * 0.75 
                                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500 + parseInt(finance.insertionJournal) * (1/3))
                                    }else if(action.payload.filter === "allFinance") {
                                        return parseInt(finance.frais) - (parseInt(finance.anpi) * 0.75 
                                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500 + parseInt(finance.insertionJournal) * (1/3))
                                    }else if(action.payload.filter === "recap_recette_caisse") {
                                        return parseInt(finance.frais) - (parseInt(finance.anpi) * 0.75 
                                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500 + parseInt(finance.insertionJournal) * (1/3))
                                    }else {
                                        return 0
                                    }
                                } else {
                                    return 0
                                } 
                            }else {
                                return ((moment(finance.dateComptable).format('L')  === action.payload.dateDeb) 
                                && finance.anpi !== "" 
                                && finance.greffe !== ""
                                && finance.insertionJournal !== ""
                                && finance.frais !== "") ? 
                            parseInt(finance.frais) - (parseInt(finance.anpi) * 0.75 
                            + parseInt(finance.greffe) * 0.5 + parseInt(finance.insertionJournal) * (1/3))
                                : 0
                            }
                           
            })
            }

        case "FINANCE_REVERSEMENT_ETAT_MOIS":
        return {
            ...state,
            montantReversementEtatMois: state.finances
                    .map(finance => (
                        ((new Date(finance.dateComptable).getMonth()  >= action.payload.dateDeb.getMonth()) 
                        && (new Date(finance.dateComptable).getFullYear()  >= action.payload.dateDeb.getFullYear()) 
                        && (new Date(finance.dateComptable).getMonth()  <= action.payload.dateFin.getMonth()) 
                        && (new Date(finance.dateComptable).getFullYear()  <= action.payload.dateFin.getFullYear()) 
                        && finance.anpi !== "" 
                        && finance.greffe !== ""
                        && finance.insertionJournal !== ""
                        && finance.frais !== "") ? 
                            parseInt(finance.frais) - (parseInt(finance.anpi) * 0.75 
                            + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500 + parseInt(finance.insertionJournal) * (1/3))
                            : 0
                    ))
        }

        case "FINANCE_DGI_JOUR":
            return {
                ...state,
                montantFinanceDGIJour: state.finances
                        .map(finance => {
                            if(action.payload.dateFin !== "") {
                                if((moment(finance.dateComptable).format('L')  >= action.payload.dateDeb) 
                                && (moment(finance.dateComptable).format('L')  <= action.payload.dateFin)
                                && finance.dgi !== "") {
                                    if(finance.nature === action.payload.filter) {
                                        return  parseInt(finance.dgi) 
                                    }  else if(finance.igu === action.payload.filter) {
                                        return parseInt(finance.dgi)
                                    } else if(action.payload.filter === "recap_recettes") {
                                        return  parseInt(finance.dgi) 
                                    }else if(action.payload.filter === "allFinance") {
                                        return  parseInt(finance.dgi) 
                                    }else if(action.payload.filter === "recap_recette_caisse_total") {
                                        return  parseInt(finance.dgi) 
                                    }else {
                                        return 0
                                    }
                                } else {
                                    return 0
                                }
                               
                            }else {
                              return  ((moment(finance.dateComptable).format('L')  === action.payload.dateDeb) && finance.dgi !== "") ? 
                                parseInt(finance.dgi) 
                                    : 0
                            }
                            })

                        
            }
        case "FINANCE_GREFFE_JOUR":
            return {
                ...state,
                montantFinanceGreffeJour: state.finances
                        .map(finance => {
                            if(action.payload.dateFin !== "") {
                                if((moment(finance.dateComptable).format('L')  >= action.payload.dateDeb) 
                                && (moment(finance.dateComptable).format('L')  <= action.payload.dateFin)
                                && finance.greffe !== "") {

                                    if(finance.nature === action.payload.filter) {
                                        return  parseInt(finance.greffe) 
                                    }  else if(finance.igu === action.payload.filter) {
                                        return parseInt(finance.greffe)
                                    }else if(action.payload.filter === "recap_recettes") {
                                        return  parseInt(finance.greffe)
                                    }else if(action.payload.filter === "allFinance") {
                                        return  parseInt(finance.greffe)
                                    }else if(action.payload.filter === "recap_recette_caisse_total") {
                                        return  parseInt(finance.greffe)
                                    }else {
                                        return 0
                                    }
                                } else {
                                    return 0;
                                }
                            }else {
                              return  ((moment(finance.dateComptable).format('L')  === action.payload.dateDeb) && finance.greffe !== "") ? 
                                parseInt(finance.greffe) 
                                    : 0
                            }
                            })
            }

        case "FINANCE_OGAPI_JOUR":
            return {
                ...state,
                montantFinanceOgapiJour: state.finances
                        .map(finance => {
                            if(action.payload.dateFin !== "") {
                                if((moment(finance.dateComptable).format('L')  >= action.payload.dateDeb) 
                                && (moment(finance.dateComptable).format('L')  <= action.payload.dateFin)
                                && finance.ogapi !== "") {

                                    if(finance.nature === action.payload.filter) {
                                        return parseInt(finance.ogapi) 
                                    } else if(finance.igu === action.payload.filter) {
                                        return parseInt(finance.ogapi)
                                    } else if(action.payload.filter === "recap_recettes") {
                                        return parseInt(finance.ogapi) 
                                    }else if(action.payload.filter === "allFinance") {
                                        return parseInt(finance.ogapi) 
                                    }else if(action.payload.filter === "recap_recette_caisse_total") {
                                        return parseInt(finance.ogapi) 
                                    }else {
                                        return 0
                                    }
                                }else {
                                    return 0;
                                }
                            }else {
                              return  ((moment(finance.dateComptable).format('L')  === action.payload.dateDeb) && finance.ogapi !== "") ? 
                                parseInt(finance.ogapi) 
                                    : 0
                            }
                            })
            }
        
        case "FINANCE_JO_JOUR":
            return {
                ...state,
                montantFinanceInsertionJournalJour: state.finances
                        .map(finance =>  {
                            if(action.payload.dateFin !== "") {
                                if((moment(finance.dateComptable).format('L')  >= action.payload.dateDeb) 
                                && (moment(finance.dateComptable).format('L')  <= action.payload.dateFin)
                                && finance.insertionJournal !== "") {

                                    if(finance.nature === action.payload.filter) {
                                        return parseInt(finance.insertionJournal) 
                                    } else if(finance.igu === action.payload.filter) {
                                        return parseInt(finance.insertionJournal)
                                    }else if(action.payload.filter === "recap_recettes") {
                                        return parseInt(finance.insertionJournal) 
                                    }else if(action.payload.filter === "allFinance") {
                                        return parseInt(finance.insertionJournal) 
                                    }else if(action.payload.filter === "recap_recette_caisse_total") {
                                        return parseInt(finance.insertionJournal) 
                                    }else {
                                        return 0
                                    }                  
                                } else {
                                    return 0;
                                } 
                                
                            }else {
                              return  ((moment(finance.dateComptable).format('L')  === action.payload.dateDeb) && finance.insertionJournal !== "") ? 
                                parseInt(finance.insertionJournal) 
                                    : 0
                            }
                            })
            }

        case "FINANCE_FRAIS_JOUR":
            return {
                ...state,
                montantFraisJour: state.finances
                        .map(finance => {
                            if(action.payload.dateFin !== "") {
                                if((moment(finance.dateComptable).format('L')  >= action.payload.dateDeb) 
                                && (moment(finance.dateComptable).format('L')  <= action.payload.dateFin)
                                && finance.frais !== "") {
                                    if(finance.nature === action.payload.filter) {
                                        return parseInt(finance.frais)
                                    } else if(finance.igu === action.payload.filter) {
                                        return parseInt(finance.frais)
                                    } else if(action.payload.filter === "recap_recettes") {
                                        return parseInt(finance.frais)
                                    }else if(action.payload.filter === "allFinance") {
                                        return parseInt(finance.frais)
                                    }else if(action.payload.filter === "recap_recette_caisse") {
                                        return parseInt(finance.frais)
                                    }else {
                                        return 0
                                    }
                                } else {
                                    return 0;
                                }
                            }else {
                              return  ((moment(finance.dateComptable).format('L')  === action.payload.dateDeb) && finance.frais !== "") ? 
                                parseInt(finance.frais) 
                                    : 0
                            }
                            })
            }    

        case "RECETTE_DROITS_JOUR":
            return {
                ...state,
                montantDroitsJour: state.recettes
                        .map(recette => (
                                ((moment(recette.dateComptable).format('L')  === action.payload) && recette.droits !== "") ? 
                            parseInt(recette.droits) 
                                : 0
                        ))
            }
        
        case "RECETTE_PENALITES_JOUR":
            return {
                ...state,
                montantPenaliteJour: state.recettes
                        .map(recette => (
                                ((moment(recette.dateComptable).format('L')  === action.payload) && recette.penalite !== "") ? 
                            parseInt(recette.penalite) 
                                : 0
                        ))
            }
        case "RECETTE_EXPRESS_JOUR":
            return {
                ...state,
                montantExpressJour: state.recettes
                        .map(recette => (
                                ((moment(recette.dateComptable).format('L')  === action.payload) && recette.formExpress !== "") ? 
                            parseInt(recette.formExpress) 
                                : 0
                        ))
            }
        case "RECETTE_EXPRESS_MOIS":
            return {
                ...state,
                montantExpressMois: state.recettes
                .map(recette => (
                        ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                        (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                        (recette.formExpress !== "")
                        ) ? 
                        parseInt(recette.formExpress)
                        : 0 
                ))
            }
        case "RECETTE_LOCATION_JOUR":
        return {
            ...state,
            montantLocationJour: state.recettes
                    .map(recette => (
                            ((moment(recette.dateComptable).format('L')  === action.payload) && recette.locationAutre !== "") ? 
                        parseInt(recette.locationAutre) 
                            : 0
                    ))
        }
        case "RECETTE_LOCATION_MOIS":
            return {
                ...state,
                montantLocationMois: state.recettes
                .map(recette => (
                        ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                        (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                        (recette.locationAutre !== "")
                        ) ? 
                        parseInt(recette.locationAutre)
                        : 0 
                ))
            }

        case "RECETTE_DFDE_JOUR":
            return {
                ...state,
                montantDfdeJour: state.recettes
                        .map(recette => (
                                ((moment(recette.dateComptable).format('L')  === action.payload) && recette.dfde !== "") ? 
                            parseInt(recette.dfde) 
                                : 0
                        ))
            }
        case "RECETTE_DFDE_MOIS":
            return {
                ...state,
                montantDfdeMois: state.recettes
                .map(recette => (
                        ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                        (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                        (recette.dfde !== "")
                        ) ? 
                        parseInt(recette.dfde)
                        : 0 
                ))
            } 
        case "RECETTE_GREFFE_JOUR":
            return {
                ...state,
                montantGreffeJour: state.recettes
                    .map(recette => (
                            ((moment(recette.dateComptable).format('L')  === action.payload) && recette.greffe !== "") ? 
                        parseInt(recette.greffe) 
                            : 0
                    ))
            }
        case "RECETTE_GREFFE_MOIS":
            return {
                ...state,
                montantGreffeMois: state.recettes
                .map(recette => (
                    ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                    (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                    (recette.greffe !== "")
                        ) ? 
                            parseInt(recette.greffe)
                        : 0 
                ))
            }  
        case "RECETTE_OGAPI_JOUR":
            return {
                ...state,
                montantOgapiJour: state.recettes
                    .map(recette => (
                            ((moment(recette.dateComptable).format('L')  === action.payload) && recette.ogapi !== "") ? 
                        parseInt(recette.ogapi) 
                            : 0
                    ))
            }
        case "RECETTE_OGAPI_MOIS":
            return {
                ...state,
                montantOgapiMois: state.recettes
                .map(recette => (
                    ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                    (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                    (recette.ogapi !== "")
                        ) ? 
                            parseInt(recette.ogapi)
                        : 0 
                ))
            }  
            
        case "RECETTE_JOURNAL_JOUR":
            return {
                ...state,
                montantInsertionJournalJour: state.recettes
                    .map(recette => (
                            ((moment(recette.dateComptable).format('L')  === action.payload) && recette.insertionJournal !== "") ? 
                        parseInt(recette.insertionJournal) 
                            : 0
                    ))
            }
        case "RECETTE_JOURNAL_MOIS":
            return {
                ...state,
                montantInsertionJournalMois: state.recettes
                .map(recette => (
                    ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                    (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                    (recette.insertionJournal !== "")
                        ) ? 
                            parseInt(recette.insertionJournal)
                        : 0 
                ))
            } 
        case "RECETTE_DGI_JOUR":
            return {
                ...state,
                montantDGIJour: state.recettes
                    .map(recette => (
                            ((moment(recette.dateComptable).format('L')  === action.payload) && recette.total !== "") ? 
                        parseInt(recette.total) 
                            : 0
                    ))
            }
        case "RECETTE_DGI_MOIS":
            return {
                ...state,
                montantDGIMois: state.recettes
                .map(recette => (
                    ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                    (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                    (recette.total !== "")
                        ) ? 
                            parseInt(recette.total)
                        : 0 
                ))
            } 
        
        case "FINANCE_DGI_MOIS":
            return {
                ...state,
                montantFinanceDGIMois: state.finances
                .map(finance => (
                    ((new Date(finance.dateComptable).getMonth()  >= action.payload.dateDeb.getMonth()) 
                    && (new Date(finance.dateComptable).getFullYear()  >= action.payload.dateDeb.getFullYear()) 
                    && (new Date(finance.dateComptable).getMonth()  <= action.payload.dateFin.getMonth()) 
                    && (new Date(finance.dateComptable).getFullYear()  <= action.payload.dateFin.getFullYear()) 
                    && (finance.dgi !== "")
                        ) ? 
                            parseInt(finance.dgi)
                        : 0 
                ))
            } 
        
        case "FINANCE_ANPI_MOIS":
            return {
                ...state,
                montantFinanceAnpiMois: state.finances
                .map(finance => {
                    if((new Date(finance.dateComptable).getMonth()  >= action.payload.dateDeb.getMonth()) 
                    && (new Date(finance.dateComptable).getFullYear() >= action.payload.dateDeb.getFullYear()) 
                    && (new Date(finance.dateComptable).getMonth()  <= action.payload.dateFin.getMonth())
                    && (new Date(finance.dateComptable).getFullYear() <= action.payload.dateFin.getFullYear()) 
                    && (finance.anpi !== "")
                        ) {
                            return  parseInt(finance.anpi)
                        } else {
                            return 0
                        }
            })
            } 

       

        case "FINANCE_GREFFE_MOIS":
            return {
                ...state,
                montantFinanceGreffeMois: state.finances
                .map(finance => (
                    ((new Date(finance.dateComptable).getMonth()  >= action.payload.dateDeb.getMonth()) 
                    && (new Date(finance.dateComptable).getFullYear() >= action.payload.dateDeb.getFullYear()) 
                    && (new Date(finance.dateComptable).getMonth()  <= action.payload.dateFin.getMonth())
                    && (new Date(finance.dateComptable).getFullYear() <= action.payload.dateFin.getFullYear()) 
                    && (finance.greffe !== "")
                        ) ? 
                            parseInt(finance.greffe)
                        : 0 
                ))
            }

        case "FINANCE_OGAPI_MOIS":
            return {
                ...state,
                montantFinanceOgapiMois: state.finances
                .map(finance => (
                    ((new Date(finance.dateComptable).getMonth()  >= action.payload.dateDeb.getMonth()) 
                    && (new Date(finance.dateComptable).getFullYear() >= action.payload.dateDeb.getFullYear()) 
                    && (new Date(finance.dateComptable).getMonth()  <= action.payload.dateFin.getMonth())
                    && (new Date(finance.dateComptable).getFullYear() <= action.payload.dateFin.getFullYear()) 
                    && (finance.ogapi !== "")
                        ) ? 
                            parseInt(finance.ogapi)
                        : 0 
                ))
            }

        case "FINANCE_JO_MOIS":
            return {
                ...state,
                montantFinanceInsertionJournalMois: state.finances
                .map(finance => (
                    ((new Date(finance.dateComptable).getMonth()  >= action.payload.dateDeb.getMonth()) 
                    && (new Date(finance.dateComptable).getFullYear() >= action.payload.dateDeb.getFullYear()) 
                    && (new Date(finance.dateComptable).getMonth()  <= action.payload.dateFin.getMonth())
                    && (new Date(finance.dateComptable).getFullYear() <= action.payload.dateFin.getFullYear()) 
                    && (finance.insertionJournal !== "")
                        ) ? 
                            parseInt(finance.insertionJournal)
                        : 0 
                ))
            }

        case "FINANCE_FRAIS_MOIS":
            return {
                ...state,
                montantFraisMois: state.finances
                .map(finance => (
                    ((new Date(finance.dateComptable).getMonth()  >= action.payload.dateDeb.getMonth()) 
                    && (new Date(finance.dateComptable).getFullYear() >= action.payload.dateDeb.getFullYear()) 
                    && (new Date(finance.dateComptable).getMonth()  <= action.payload.dateFin.getMonth())
                    && (new Date(finance.dateComptable).getFullYear() <= action.payload.dateFin.getFullYear())
                    && (finance.frais !== "")
                        ) ? 
                            parseInt(finance.frais)
                        : 0 
                ))
            }

        case "FINANCE_ANPI_REEL_MOIS":
            return {
                ...state,
                montantFinanceAnpiReelMois: state.finances
                .map(finance => (
                    ((new Date(finance.dateComptable).getMonth()  >= action.payload.dateDeb.getMonth()) 
                    && (new Date(finance.dateComptable).getFullYear() >= action.payload.dateDeb.getFullYear()) 
                    && (new Date(finance.dateComptable).getMonth()  <= action.payload.dateFin.getMonth())
                    && (new Date(finance.dateComptable).getFullYear() <= action.payload.dateFin.getFullYear())
                    && finance.anpi !== ""  
                    && finance.greffe !== "" 
                    && finance.insertionJournal !== ""
                        ) ? 
                        parseInt(finance.anpi) * 0.75 
                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.nbre_operation_ogapi) * 3500 + parseInt(finance.insertionJournal) * (1/3)
                        : 0 
                ))
            }
        
        case "FINANCE_ANPI_GLOBAL_MOIS":
            return {
                ...state,
                montantFinanceGlobalAnpiMois: state.finances
                .map(finance => (
                    ((new Date(finance.dateComptable).getMonth()  === action.payload.getMonth()) && 
                    (new Date(finance.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                    finance.anpi !== ""  && 
                    finance.greffe !== "" && 
                    finance.insertionJournal !== ""
                        ) ? 
                        parseInt(finance.anpi) * 0.75 
                        + parseInt(finance.greffe) * 0.5 + parseInt(finance.insertionJournal) * (1/3)
                        : 0 
                ))
            }

        case "RECETTE_TIMBRE_JOUR":
            return {
                ...state,
                montantTimbreJour: state.recettes
                    .map(recette => (
                            ((moment(recette.dateComptable).format('L')  === action.payload) && recette.timbres !== "") ? 
                        parseInt(recette.timbres) 
                            : 0
                    ))
            }
        case "RECETTE_TIMBRE_MOIS":
            return {
                ...state,
                montantTimbreMois: state.recettes
                .map(recette => (
                    ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                    (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                    (recette.timbres !== "")
                        ) ? 
                            parseInt(recette.timbres)
                        : 0 
                ))
            } 
        case "RECETTE_DROITS_MOIS":
            return {
                ...state,
                montantDroitsMois: state.recettes
                        .map(recette => (
                            
                                ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                                (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                                (recette.droits !== "")
                                ) ? 
                                parseInt(recette.droits)
                                : 0 
                        ))
            }

        case "RECETTE_PENALITES_MOIS":
            return {
                ...state,
                montantPenaliteMois: state.recettes
                        .map(recette => (
                            
                                ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                                (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) &&
                                (recette.penalite !== "")
                                ) ? 
                                parseInt(recette.penalite)
                                : 0 
                        ))
            }
        
        case "RECETTE_PARTENAIRES_MOIS":
            return {
                ...state,
                montantPartenairesMois: state.recettes
                        .map(recette => {
                            
                                if((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                                (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear()) 
                                ) {
                                   
                                   /*  console.log(`${parseInt(recette.total)}  ${parseInt(recette.greffe)}  ${parseInt(recette.ogapi)}
                                     ${parseInt(recette.insertionJournal)}`) */

                                    return parseInt(recette.total) + parseInt(recette.greffe) + parseInt(recette.ogapi)
                                    + parseInt(recette.insertionJournal)
                                } else {
                                    return 0
                                }
                                
                            })
            }

        case "RECETTE_MOIS":
            return {
                ...state,
                montantRecetteMois: state.recettes
                        .map(recette => (
                            
                                ((new Date(recette.dateComptable).getMonth()  === action.payload.getMonth()) && 
                                (new Date(recette.dateComptable).getFullYear()  === action.payload.getFullYear())
                                ) ? 
                                recette.totalFinal
                                : 0 
                        ))
            }
        default:
            break;
    }
}