import React, { useState, useContext, useEffect } from 'react'
import AuthContext from '../context/auth/AuthContext'
import AlertContext from '../context/alert/AlertContext'

const Login = (props) => {

  const authContext = useContext(AuthContext)
  const alertContext = useContext(AlertContext)

  const { login, isAuthenticated, error } = authContext
  const { setAlert } = alertContext
  const [user, setUser] = useState({
    email: '',
    password: '',
  })

  //const [errors, setErrors] = useState({})

  const { email, password } = user

  const onChange = (e) => {
    setUser({...user, [e.target.name]: e.target.value })
  }
  
  const onSubmit = (e) => {
    e.preventDefault()
    if(email !== '' || password !== '') {
      login(user)
      //setAlert(`${error}`, "danger")
      /* .then(res => {
        if (res) {
          
          props.history.push(`/profile`)
        }
      }) */
    }else {
      setAlert("vos champs sont vides...", "danger")
    }
     
  }
 
  useEffect(() => {
    if(isAuthenticated) {
        setAlert("Connection effectuée", "success")
         props.history.push('/profile')
    }
 }, [isAuthenticated, props.history])

  
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-5 mx-auto">
            <form noValidate onSubmit={onSubmit}>
              <h1 className="h3 mb-3 font-weight-normal">Connectez- vous</h1>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Entrer votre email"
                  value={email} required
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Mot de passe</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Entrer votre mot de passe"
                  value={password} required
                  onChange={onChange}
                />
              </div>
              <button
                type="submit"
                className="btn btn-lg btn-primary btn-block"
              >
                Se connecter
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  
}

export default Login