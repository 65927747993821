import React, { useReducer } from 'react';
import AuthContext from './AuthContext';
import AuthReducer from './AuthReducer';
import axios from 'axios'
import setAuthToken from '../../utils/setAuthToken';

const AuthState = (props) => {

    //const url = 'http://169.254.2.250:5000'
    //const url = 'http://localhost:5000'
    const url = ''
    const initialState = {
        token: localStorage.getItem('usertoken'),
        isAuthenticated: null,
        isRegisted: null,
        loading: true,
        user: null,
        error: null
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState)

    // Load user
    const loadUser = async () => {
        //  @todo - load token into global header
        if(localStorage.usertoken) {
            console.log(`all ---- ${localStorage.usertoken}`)
            setAuthToken(localStorage.usertoken);
        }
        let res = null;
        try {
            res = await axios.get(`${url}/users/profile`);
            
            
             dispatch({
                type: "USER_LOADED",
                payload: res.data
            }) 

        } catch (error) {
             dispatch({
                type: "AUTH_ERROR"
            })
        }
    }

    // Register user
    const register = async newUser => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            console.log("auth register")
           

            const res = await axios.post(`${url}/users/register`, {
                first_name: newUser.first_name,
                last_name: newUser.last_name,
                email: newUser.email,
                password: newUser.password,
                departement: newUser.departement
            }, config)

            dispatch({
                type: "REGISTER_SUCCESS",
                payload: res.data
            })  

            //loadUser()
        } catch (error) {
            dispatch({
                type: "REGISTER_FAIL",
                payload: error.response.data.msg
            })
        }
    }

    const changeStateRegister = () => dispatch({type: "CHANGE_REGISTER_STATE"})

    //Login user
    const login = async formdata => {

        const config = {
            headers: {
                'Content-Type': 'application/json' 
            }
        }

        try {
            const res = await axios.post(`${url}/users/login`, 
            {
                email: formdata.email,
                password: formdata.password
            }, config)

             dispatch({
                type: "LOGIN_SUCCESS",
                payload: res.data
            }) 
            console.log(res)
            loadUser()
        } catch (error) {
            dispatch({
                type: "LOGIN_FAIL",
                payload: error
            })
        }
    }

    //   Logout
    const logout = () => dispatch({type: "LOGOUT"})

    return (
        <AuthContext.Provider value={{
            token: state.token,
            isAuthenticated: state.isAuthenticated,
            isRegisted: state.isRegisted,
            loading: state.loading,
            user: state.user,
            error: state.error,
            register,
            loadUser,
            login,
            logout,
            changeStateRegister
        }}>
            { props.children }
        </AuthContext.Provider>
    );
};

export default AuthState;