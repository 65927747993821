import React, { Fragment, useState, useContext, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import RecetteContext from "../context/RecetteContext"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import NumberFormat from 'react-number-format';

const RecetteUpdate = ({onShow, change}) => {

    const recetteContext = useContext(RecetteContext)
    const { selectedRecette, updateRecette, clearRecette, clearSeletedRecette, recettes } = recetteContext
    const [startDate, setStartDate] = useState();
    const [newRecette, setnewRecette] = useState({
        id:"",
        dateComptable: "", quantite: "",
        ordre: "", formExpress: "",
        locationAutre: "", dfde: "",
        droits: "", penalite: "",
        timbres: "", total: "0",
        greffe: "", ogapi: "",
        insertionJournal: "",
        totalFinal: ""
    })

    const [errors, setErrors] = useState(false)
    const [droitsTemp, setDroitsTemp] = useState(0)
    const [penalTemp, setPenalTemp] = useState(0)
    const [totalDGI, setTotalDGI] = useState(0);
    const [timbreTemp, setTimbreTemp] = useState(0)

    const [totalLigne, setTotalLigne] = useState(0)
    const [expressTemp, setExpressTemp] = useState(0)
    const [locationTemp, setLocationTemp] = useState(0)
    const [dfdeTemp, setDfdeTemp] = useState(0)
    const [greffeTemp, setGreffeTemp] = useState(0)
    const [ogapiTemp, setOgapiTemp] = useState(0)
    const [journalTemp, setJournalTemp] = useState(0)

    const [colorQt, setcolorQt] = useState("none")
    const [colorOrdre, setColorOrdre] = useState("none")

    const qtRef = useRef(null)
    const inputOrdreRef = useRef(null)
    let formExpressRef = useRef(null)
    let locationAutreRef = useRef(null)
    let dfdeRef = useRef(null)
    let penaliteRef = useRef(null)
    let droitsRef = useRef(null);
 
    
    let timbreRef = useRef(null);

    let greffeRef = useRef(null);
    let ogapiRef = useRef(null);
    let insertionJournalRef = useRef(null);

    const { 
        id,
        dateComptable,quantite,ordre,
        formExpress,locationAutre,dfde,
        droits,penalite,timbres,
        total,greffe,ogapi,
        insertionJournal,totalFinal 
    } = newRecette;

    useEffect(() => {
        if(selectedRecette !== null) {
           
            
            setnewRecette(selectedRecette)
            setStartDate(moment(selectedRecette.dateComptable).toDate()) 
            setTotalDGI(parseInt(selectedRecette.total))
            setTotalLigne(parseInt(selectedRecette.totalFinal))
            
            setDroitsTemp(parseInt(selectedRecette.droits))
            setPenalTemp(parseInt(selectedRecette.penalite))
            setExpressTemp(parseInt(selectedRecette.formExpress))
            setLocationTemp(parseInt(selectedRecette.locationAutre))
            setDfdeTemp(parseInt(selectedRecette.dfde))
            setGreffeTemp(parseInt(selectedRecette.greffe))
            setOgapiTemp(parseInt(selectedRecette.ogapi))
            setJournalTemp(parseInt(selectedRecette.insertionJournal)) 
            setTimbreTemp(parseInt(selectedRecette.timbres))
        }

    }, [selectedRecette])

    useEffect(() => {
        handleTotalDGI()
        handleTotalLigne() 
    }, [droitsTemp, penalTemp,
        expressTemp, locationTemp,
         dfdeTemp, greffeTemp,
       ogapiTemp, journalTemp, timbreTemp])

    const handleModal = () => {
        change()
        clearSeletedRecette()
    }

    const handleValue = (e) => {
          setnewRecette({ ...newRecette, [e.target.name]: e.target.value})
      }

      const onChangeDate = (date) => {
        setStartDate(date)
   }
    const handleUpdate = (e) => {
        e.preventDefault();
        newRecette.dateComptable = startDate;

        newRecette.droits = droitsTemp;
        newRecette.penalite = penalTemp;
        newRecette.timbres = timbreTemp;

        newRecette.total = totalDGI;

        newRecette.formExpress = expressTemp;
        newRecette.locationAutre = locationTemp;
        newRecette.dfde = dfdeTemp;
        newRecette.greffe = greffeTemp;
        newRecette.ogapi = ogapiTemp;
        newRecette.insertionJournal = journalTemp;
        newRecette.totalFinal = totalLigne;

        updateRecette(newRecette)

        clearRecette()
        handleModal()
    }  

    const handleTotalDGI = () => {
        if(isNaN(droitsTemp)) {
            setDroitsTemp(0)
        }
        if(isNaN(penalTemp)) {
            setPenalTemp(0)
        }
        if(isNaN(timbreTemp)) {
            setTimbreTemp(0)
        }
        setTotalDGI(droitsTemp + penalTemp + timbreTemp)
    }
 
     const handleDroitTemp = (e) => {
        
         setDroitsTemp(parseInt(e))
     }

     const handlePenalTemp = (e) => {
        
      setPenalTemp(parseInt(e))
    }

    const handleTimbreTemp = (e) => {
        setTimbreTemp(parseInt(e))
    }

    // total ligne
    const handleexpressTemp = (e) => {
        
        setExpressTemp(parseInt(e))
    }

    const handleLocationTemp = (e) => {
        
        setLocationTemp(parseInt(e))
    }

    const handleDfdeTemp = (e) => {
       
        setDfdeTemp(parseInt(e))
    }

    const handleGreffeTemp = (e) => {
        
        setGreffeTemp(parseInt(e))
    }

    const handleOgapiTemp = (e) => {
        
        setOgapiTemp(parseInt(e))
    }

    const handleJournalTemp = (e) => {
        
        setJournalTemp(parseInt(e))
    }

    const handleTotalLigne = () => {
        if(isNaN(expressTemp)) {
            setExpressTemp(0)
        }
        if(isNaN(locationTemp)) {
            setLocationTemp(0)
        }
        if(isNaN(dfdeTemp)) {
            setDfdeTemp(0)
        }
        if(isNaN(droitsTemp)) {
            setDroitsTemp(0)
        }
        if(isNaN(penalTemp)) {
            setPenalTemp(0)
        }
        if(isNaN(greffeTemp)) {
            setGreffeTemp(0)
        }
        if(isNaN(ogapiTemp)) {
            setOgapiTemp(0)
        }
        if(isNaN(journalTemp)) {
            setJournalTemp(0)
        }
        if(isNaN(timbreTemp)) {
            setTimbreTemp(0)
        }

        setTotalLigne(expressTemp + locationTemp 
            + dfdeTemp + droitsTemp + penalTemp + greffeTemp
             + ogapiTemp + journalTemp + timbreTemp) 
    }


    const handleQuantite = (e) => {
        var q = null;        
        q = recettes.find(recette => parseInt(recette.quantite) === parseInt(e.target.value)) 
        if(typeof q === 'undefined') {
            setcolorQt("white") 
            setErrors(false)
        } else {
            setcolorQt("red")
            setErrors(true)
        } 
    } 

    const handleOrdre = (e) => {
        var q = null;        
        q = recettes.find(recette => parseInt(recette.ordre) === parseInt(e.target.value)) 
        if(typeof q === 'undefined') {
            setColorOrdre("white") 
            setErrors(false)
        } else {
            setColorOrdre("red")
            setErrors(true)
        } 

    }

    return (
        <Fragment>
          <Modal size="lg" show={onShow} onHide={handleModal}>
                  <Modal.Header closeButton>
                      <Modal.Title>Mise à jour recette</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ backgroundColor: "grey"}}>
                  <form onSubmit={handleUpdate}>
                  <div className="form-row" >
                        <div className="form-group col-md-4">
                            <label htmlFor="dateComptable">Date Comptable</label>
                            <DatePicker className="form-control" 
                                        selected={startDate}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={onChangeDate}  />
                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="NºQA">NºQA</label>  
                        {/* <input type="number" className="form-control" name="quantite" value={quantite} onChange={handleValue} placeholder="NºQA"/> */}
                        
                        <input type="number" className="form-control"
                                            required
                                            style={{ backgroundColor: colorQt}}
                                            name="quantite" 
                                            onKeyPress={e => {
                                                const { key } = e;
                                                if(key === "Enter") {
                                                    e.preventDefault()
                                                    inputOrdreRef.current.focus()
                                                }
                                            }} ref={qtRef}
                                            value={quantite}
                                            onChange={handleValue} placeholder="NºQA"/>
                        
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="NºOR">NºOR</label>  
                            {/* <input type="number" className="form-control" name="ordre" value={ordre} onChange={handleValue} placeholder="NºOR"/> */}
                        
                            <input type="number" className="form-control"
                                                 style={{ backgroundColor: colorOrdre}}
                                                 name="ordre"  required
                                                 value={ordre}   onKeyPress={e => {
                                                    const { key } = e;
                                                    if(key === "Enter") {
                                                        e.preventDefault()
                                                        formExpressRef.focus()
                                                    }
                                                }} ref={inputOrdreRef}
                                                 onChange={handleValue} placeholder="NºOR"/>
                        
                        </div>
                </div>
                <br/>
                <h5>Recette anpi-gabon</h5>
                <br/>
                <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="FormuleExpress">Formule Express</label>
                            {/* <input type="number" className="form-control" name="formExpress" onKeyUp={handleexpressTemp} value={formExpress} onChange={handleValue} placeholder="Formule Express" /> */}
                            <NumberFormat className="form-control"
                                   placeholder="Formule Express"
                                    name="formExpress"
                                    value={formExpress} 
                                    onValueChange={(values) => {
                                       const  {value} = values;
                                       handleexpressTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            locationAutreRef.focus()
                                        }
                                    }}
                                    ref={formExpressRef}
                                    getInputRef = {(el) => formExpressRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>  
                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="LocationAutres">Location et autres</label>  
                        {/* <input type="number" className="form-control" name="locationAutre" onKeyUp={handleLocationTemp} value={locationAutre} onChange={handleValue} placeholder="Location et autres"/> */}
                        
                        <NumberFormat className="form-control"
                                   placeholder="Formule Express"
                                    name="locationAutre"
                                    value={locationAutre} 
                                    onValueChange={(values) => {
                                       const  {value} = values;
                                       handleLocationTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            dfdeRef.focus()
                                        }
                                    }}
                                    ref={locationAutreRef}
                                    getInputRef = {(el) => locationAutreRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/> 

                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="dfde">Autre DFDE</label>  
                            {/* <input type="number" className="form-control" name="dfde" onKeyUp={handleDfdeTemp} value={dfde} onChange={handleValue} placeholder="Autre DFDE"/> */}
                        
                            <NumberFormat className="form-control"
                                   placeholder="Autre DFDE"
                                    name="dfde"
                                    value={dfde} 
                                    onValueChange={(values) => {
                                       const  {value} = values;
                                       handleDfdeTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            droitsRef.focus()
                                        }
                                    }}
                                    ref={dfdeRef}
                                    getInputRef = {(el) => dfdeRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/> 
                        
                        </div>
                </div>
                <br/>
                <h5>Droit d'enregistrement (DGI)</h5>
                <br/>
                <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="Droit">Droit (code 38)</label>
                            {/* <input type="number" className="form-control" name="droits" value={droits} onKeyUp={handleDroitTemp} onChange={handleValue}  placeholder="Droit (code 38)" /> */}
                        
                            <NumberFormat className="form-control"
                                placeholder="Droit (code 38)"
                                    name="droits"
                                    value={droits} 
                                    onValueChange={(values) => {
                                    const  {value} = values;
                                    handleDroitTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            penaliteRef.focus()
                                        }
                                    }}
                                    ref={droitsRef}
                                    getInputRef = {(el) => droitsRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>
                        
                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="Penalite">Penalite (code 81)</label>  
                        {/* <input type="number" className="form-control" name="penalite" value={penalite} onKeyUp={handlePenalTemp} onChange={handleValue}  placeholder="Penalite (code 81)"/> */}
                        
                        <NumberFormat className="form-control"
                                placeholder="Penalite (code 81)"
                                    name="penalite"
                                    value={penalite} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handlePenalTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            timbreRef.focus()
                                        }
                                    }}
                                    ref={penaliteRef}
                                    getInputRef = {(el) => penaliteRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>
                        
                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="Timbre">Timbre (code 75)</label>  
                        {/* <input type="number" className="form-control" name="timbres" value={timbres} onKeyUp={handleTimbreTemp} onChange={handleValue} placeholder="Timbre (code 75)"/> */}
                       
                        <NumberFormat className="form-control"
                                placeholder="Timbre (code 75)"
                                    name="timbres"
                                    value={timbres} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handleTimbreTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            greffeRef.focus()
                                        }
                                    }}
                                    ref={timbreRef}
                                    getInputRef = {(el) => timbreRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>
                       
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="totalDGI">Total GDI</label>  
                            {/* <input type="number" disabled className="form-control" id="totalDGI" value={totalDGI} placeholder="Total"/> */}
                            <NumberFormat className="form-control"
                            disabled
                            placeholder="Total"
                            value={totalDGI}
                            thousandSeparator={'.'}
                            decimalSeparator={','}/>
                        
                        </div>
                </div>
                <br/>
               
                <br/>
                <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="FraisGreffe">Frais de Greffe</label>
                            {/* <input type="number" className="form-control" name="greffe" value={greffe} onKeyUp={handleGreffeTemp} onChange={handleValue} placeholder="Frais de Greffe" /> */}
                        
                            <NumberFormat className="form-control"
                                placeholder="Frais de Greffe"
                                    name="greffe"
                                    value={greffe} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handleGreffeTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            ogapiRef.focus()
                                        }
                                    }}
                                    ref={greffeRef}
                                    getInputRef = {(el) => greffeRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}/>

                        </div>
                        <div className="form-group col-md-4">
                        <label htmlFor="FraisOgapi">Frais Ogapi</label>  
                        {/* <input type="number" className="form-control" name="ogapi" value={ogapi} onKeyUp={handleOgapiTemp} onChange={handleValue} placeholder="Frais Ogapi"/> */}
                        
                        <NumberFormat className="form-control"
                                placeholder="Frais Ogapi"
                                    name="ogapi"
                                    value={ogapi} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handleOgapiTemp(value)
                                    }}
                                    onKeyPress={e => {
                                        const { key } = e;
                                        if(key === "Enter") {
                                            e.preventDefault()
                                            insertionJournalRef.focus()
                                        }
                                    }}
                                    ref={ogapiRef}
                                    getInputRef = {(el) => ogapiRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','} />
                        
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="InsertionJournalOfficiel">Insertion Journal Officiel</label>  
                            {/* <input type="number" className="form-control" name="insertionJournal" onKeyUp={handleJournalTemp} value={insertionJournal} onChange={handleValue} placeholder="Insertion journal officiel"/> */}
                        
                            <NumberFormat className="form-control"
                                    placeholder="Insertion journal officiel"
                                    name="insertionJournal"
                                    value={insertionJournal} 
                                    onValueChange={(values) => {
                                        const  {value} = values;
                                        handleJournalTemp(value)
                                    }}
                                   
                                    ref={insertionJournalRef}
                                    getInputRef = {(el) => insertionJournalRef = el}
                                    onChange={handleValue}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','} />

                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="total">Total</label>  
                            {/* <input type="number" disabled className="form-control" id="total" value={totalLigne} placeholder="Total"/> */}
                            <NumberFormat className="form-control"
                            disabled
                            placeholder="Total"
                            value={totalLigne}
                            thousandSeparator={'.'}
                            decimalSeparator={','}/>
                        </div>
                </div>
                        <button disabled={errors} type="submit" className="btn btn-primary">Submit</button>
                    </form>
  
                  </Modal.Body>
                 
              </Modal>
        </Fragment>
      );
};

export default RecetteUpdate;