import React, { useState, useContext, useEffect } from 'react';
import RecetteContext from "../context/RecetteContext"
import DatePicker, { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr'
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ShowTable from './ShowTable';
import JsfPdfDoc from '../Etats/JsPdfDoc';
import PartnersMois from './PartnersMois';
const RecetteMois = () => {

    registerLocale("fr", fr);
    let total;
    const recetteContext = useContext(RecetteContext)
    const [listMontant, setListMontant] = useState(null)
    const [startDate, setStartDate] = useState(new Date())
    const [selectPartner, setSelectPartner] = useState();
    const [restantPartenaire, setrestantPartenaire] = useState(0)

    const { allRecettes, 
        recetteDroitMois, recettePenaliteMois, recetteDGIMois,
        recettePartenaireMois ,recetteMois, recetteTimbreMois,
        recetteExpressMois,recetteLocationMois, allRecettesByMonth,
        recetteDfdeMois, recetteGreffeMois,
        recetteOgapiMois,recetteInsertionJournalMois ,filterRecettes,
        montantDroitsMois, montantPenaliteMois, montantDGIMois, 
        montantExpressMois, montantLocationMois, montantDfdeMois, 
        montantGreffeMois, montantOgapiMois, montantInsertionJournalMois,
        montantRecetteMois, montantTimbreMois, montantPartenairesMois } = recetteContext
   

   useEffect(() => {
    allRecettesByMonth(startDate)
    recetteDGIMois(startDate)
    recetteMois(startDate)
    recetteDroitMois(startDate)
    recettePenaliteMois(startDate)
    recettePartenaireMois(startDate)
    recetteExpressMois(startDate)
    recetteLocationMois(startDate)
    recetteDfdeMois(startDate)
    recetteGreffeMois(startDate)
    recetteOgapiMois(startDate)
    recetteInsertionJournalMois(startDate)
    recetteTimbreMois(startDate)
    recettePartenaireMois(startDate)
    
   }, [startDate])

   useEffect(() => {
    handleMontantPartner()
   }, [filterRecettes, selectPartner])

   useEffect(() => {
    allRecettes()
   }, [])

   const handlePartner = (e) => {
       setSelectPartner(e.target.value)
   }

   const handleMontantPartner = () => {
       
    switch (selectPartner) {
        case "anpi":
            setListMontant([montantExpressMois, montantLocationMois, montantDfdeMois, montantRecetteMois])
            break;
        case "journal":
            setListMontant([montantInsertionJournalMois])
            break;
        case "ogapi":
            setListMontant([montantOgapiMois])
            break;
        case "greffe":
            setListMontant([montantGreffeMois])
            break;
        case "dgi":
            setListMontant([montantDroitsMois, montantPenaliteMois, montantTimbreMois, montantDGIMois])
            break;
        case "droits":
            setListMontant([montantDroitsMois])
            break;
        case "penalites":
            setListMontant([montantPenaliteMois])
            break;
        case "timbres":
            setListMontant([montantTimbreMois])
            break;
        case "tout-sans-anpi":
            setListMontant([montantDGIMois, montantGreffeMois, montantOgapiMois, montantInsertionJournalMois, montantPartenairesMois])
            break;
        case "etat":
            setListMontant([montantDfdeMois])
            break;
        default:
            setListMontant([montantExpressMois, montantLocationMois, montantDfdeMois,
                montantDroitsMois, montantPenaliteMois, montantTimbreMois, montantDGIMois,
                montantGreffeMois, montantOgapiMois, montantInsertionJournalMois, montantRecetteMois])
            break;
    }
}
   const handleDate = (date) => {
        setStartDate(date)
   }

    return (
        <div style={{ marginTop: "30px"}}>
            
            <div style={{ margin: "20px"}}>
               <h1 className="display-4">
                    Suivie des recettes par mois
               </h1>
            </div>
            <Row>
                <Col>
                    <div style={{ margin: "20px"}}>
                    
                        <label>Selectionner le mois : </label>{"  "}
                        <br />
                        <DatePicker className="form-control" 
                                    selected={startDate}
                                    dateFormat="MM/yyyy"
                                    locale="fr"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    onChange={handleDate}/> 
                    </div>
                </Col>

                <Col>
                    <div style={{ margin: "20px"}}>
                        
                        <label for="selectPartenaires">Trier par partenaire : </label>{"  "}
                        <select class="form-control" id="selectPartenaires" value={selectPartner} onChange={handlePartner}>
                            <option value="tout">Tout</option>
                            <option value="anpi">Anpi</option>
                            <option value="dgi">DGI</option>
                            <option value="droits">--Droits</option>
                            <option value="penalites">--Pénalités</option>
                            <option value="timbres">--Timbres</option>
                            <option value="greffe">Greffe</option>
                            <option value="ogapi">Ogapi</option>
                            <option value="journal">Insertion Journal Officiel</option>
                            <option value="tout-sans-anpi">Partenaires (sans ANPI)</option>
                            <option value="etat">Etat</option>
                        </select>
                    </div>
                </Col>
                <Col>
                    <div style={{ margin: "20px"}}>
                        <label>Options : </label>
                        <Row>
                            <Col>
                                <JsfPdfDoc source="table-to-xls" title="SUIVI DES RECETTES"/>
                            </Col>
                            <Col>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="btn btn-primary download-table-xls-button"
                                    table="table-to-xls"
                                    filename="tablexls"
                                    sheet="tablexls"
                                    buttonText="Telecharger excel"/>
                            </Col>
                        </Row>
                    </div>
                    
                </Col>
            </Row>
            {selectPartner === "tout-sans-anpi" ? 
             <PartnersMois dateCompt={startDate} montantPartenairesMois={montantPartenairesMois} /> : null
            }
           

            <ShowTable choix={selectPartner} filterRecettes={filterRecettes} listMontant={listMontant}/>
    </div>
    );
};

export default RecetteMois;